import React from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectIsUserAuthenticated } from "../store/selectors/authSelectors";
import ErrorCard from "./common/ErrorCard";
import { decryptString } from "@groov/ui";
import { TOKEN_EXCHANGE_ENCRYPTION_KEY } from "../config/app.config";
import { loginTokenExchangeAction } from "../store/actions/authActions";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useQueryString } from "../hooks/useQueryStrings";

const ProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const isUserAuthenticated = useAppSelector(selectIsUserAuthenticated);
  const accessToken = useQueryString().get("key") as string;
  if (accessToken) {
    const decryptedAccessToken = decryptString(
      TOKEN_EXCHANGE_ENCRYPTION_KEY,
      decodeURIComponent(accessToken)
    );

    dispatch(loginTokenExchangeAction(decryptedAccessToken));
  }

  if (!isUserAuthenticated) {
    return <ErrorCard error={{ error: "User not authenticated" }} />;
  }

  return <Outlet />;
};
export default ProtectedRoute;
