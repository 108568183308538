import React from "react";
import { PILLAR_MOVE_IMAGE_ID } from "../../../constants/test-ids/pillarBadge.ids";

const PillarMove = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={PILLAR_MOVE_IMAGE_ID}
    >
      <path
        d="M76 40C76 59.8822 59.8822 76 40 76C20.1177 76 4 59.8822 4 40C4 20.1177 20.1178 4 40 4C59.8822 4 76 20.1177 76 40Z"
        fill="#EDF7FF"
      />
      <path
        d="M62.2289 18.7368C67.6945 18.7368 72.1247 25.5245 72.1247 33.8974C72.1247 42.2704 67.6945 49.058 62.2289 49.058C61.4626 49.058 60.7177 48.9254 60.0021 48.672C59.965 48.1358 59.9065 47.6014 59.8266 47.0691C59.4561 44.6298 58.6255 42.2626 57.3483 40.1508C57.4088 39.9598 57.4634 39.7686 57.514 39.5756C57.5452 39.4606 57.5744 39.3475 57.6018 39.2324C58.413 39.2129 59.9787 39.3182 59.9787 39.3182C60.8503 39.133 62.2231 35.3599 62.0456 33.0511C61.9383 31.6687 61.7532 29.1728 59.5477 28.1491C59.0174 28.1491 57.5121 28.1433 57.0323 28.3655L57.0129 28.3752C57.011 28.3694 57.007 28.3635 57.0051 28.3578C56.3675 27.0512 55.3847 25.8852 54.1504 25.1384C55.6246 21.9542 57.8806 19.6787 60.4915 18.9708C61.0551 18.8168 61.6361 18.7368 62.2289 18.7368Z"
        fill="#6CBEE3"
      />
      <path
        d="M57.6767 30.1593V30.1574C57.5109 29.5452 57.2925 28.9485 57.0137 28.3752L57.0331 28.3654C57.5129 28.1432 59.0182 28.1489 59.5486 28.1489C61.754 29.1726 61.9391 31.6686 62.0464 33.0511C62.2239 35.3598 60.8511 39.1329 59.9795 39.3182C59.9795 39.3182 58.4138 39.2129 57.6026 39.2323V39.2304C57.7235 38.7351 57.8268 38.2359 57.9068 37.7328V37.7309C58.1213 36.4361 58.2051 35.1219 58.1719 33.8115C58.1408 32.5812 58.0022 31.343 57.6767 30.1593Z"
        fill="#0A4027"
      />
      <path
        d="M36.9527 44.5673C37.3582 44.0096 37.7014 43.3993 38.0135 42.7831V42.7792C38.031 42.748 38.0466 42.7168 38.0622 42.6856C38.7135 41.3831 39.2751 40.0142 40.1837 38.8755C41.0923 37.7367 42.4378 36.8319 43.8944 36.8826C45.0527 36.9216 46.1349 37.569 46.8739 38.4621C47.6109 39.3552 48.0341 40.4725 48.274 41.6072C48.3227 41.8355 48.3635 42.0636 48.3968 42.2936V42.2976C48.426 42.4828 48.4494 42.67 48.4689 42.8572C48.6678 43.68 49.2528 44.4268 49.9859 44.8715C51.0038 45.4857 52.3142 45.5208 53.4276 45.0996C54.541 44.6785 55.4652 43.838 56.1262 42.8474C56.6781 42.0245 57.0622 41.1003 57.3488 40.1507C58.626 42.2625 59.4567 44.6296 59.8271 47.0691C59.9071 47.6014 59.9657 48.1356 60.0027 48.6718C60.2211 51.8405 59.6731 55.0636 58.3355 57.9476C55.709 63.616 50.1146 67.8025 43.9353 68.7268C37.756 69.649 31.1829 67.278 27.0139 62.6254C24.3426 59.644 22.6832 55.869 21.8682 51.9341V51.9321C25.1927 50.3585 27.8348 46.369 28.9482 41.2855C29.112 41.966 29.3129 42.6096 29.5936 43.1321C30.0675 44.0096 31.0912 45.2185 32.1539 45.5695C33.6086 46.0492 35.6599 45.9419 36.9488 44.5653L36.9527 44.5673ZM46.9128 43.0522L46.9148 43.0386C46.9733 42.0129 46.8641 40.8897 46.1563 40.1468C45.6454 39.6105 44.8577 39.3571 44.1264 39.478C43.3952 39.6008 42.7381 40.0883 42.3949 40.7454C42.0517 41.4026 42.0263 42.3346 42.313 43.019L42.3208 43.0522C43.832 42.7577 45.4016 42.7577 46.9128 43.0522Z"
        fill="#FFC271"
      />
      <path
        d="M54.1253 43.3133C54.2755 42.7497 54.0492 42.1043 53.6183 41.6968C53.127 41.2308 52.423 41.0436 51.7465 41.0455C50.8572 41.0475 49.9057 41.4063 49.4943 42.2135C49.4241 42.35 49.387 42.506 49.4124 42.6581C49.4748 43.0227 49.8004 43.1046 50.091 43.1612C51.3213 43.3991 52.5634 43.5706 53.8075 43.5511C53.9538 43.5493 54.0883 43.4537 54.1253 43.3133M57.3486 40.1505C57.0619 41.1001 56.6778 42.0243 56.126 42.8473C55.465 43.8378 54.5406 44.6782 53.4272 45.0994C52.3138 45.5206 51.0035 45.4855 49.9857 44.8713C49.2524 44.4267 48.6675 43.6798 48.4686 42.857C48.4491 42.6698 48.4257 42.4826 48.3965 42.2973V42.2934C48.3945 42.155 48.4043 42.0127 48.4276 41.8743C49.5294 37.8575 49.7068 33.5871 48.9425 29.4922C48.6987 28.1858 48.3497 26.8774 47.8037 25.6685C49.1101 24.3288 51.2472 23.9877 53.0158 24.6018C53.4136 24.7403 53.7939 24.9217 54.1507 25.1381C55.385 25.8849 56.3677 27.0509 57.0054 28.3574C57.0073 28.3632 57.0112 28.3691 57.0131 28.3749C57.292 28.9482 57.5104 29.5449 57.6762 30.1572V30.1591C58.0017 31.3427 58.1402 32.5809 58.1714 33.8114C58.2045 35.1217 58.1207 36.4359 57.9062 37.7306V37.7326C57.8263 38.2356 57.7229 38.7349 57.6021 39.2301V39.2321C57.5747 39.3471 57.5455 39.4602 57.5142 39.5753C57.4635 39.7683 57.4089 39.9594 57.3486 40.1505Z"
        fill="#FFC271"
      />
      <path
        d="M53.6183 41.697C54.0492 42.1045 54.2754 42.7499 54.1253 43.3135C54.0882 43.4539 53.9536 43.5493 53.8074 43.5513C52.5633 43.5708 51.3213 43.3993 50.0909 43.1613C49.8003 43.1048 49.4747 43.0229 49.4123 42.6583C49.3869 42.5061 49.424 42.3502 49.4942 42.2137C49.9056 41.4064 50.8571 41.0477 51.7464 41.0457C52.4229 41.0437 53.1269 41.231 53.6183 41.697Z"
        fill="white"
      />
      <path
        d="M47.8024 25.6686C48.3483 26.8776 48.6974 28.186 48.9411 29.4924C49.7055 33.5872 49.5281 37.8575 48.4263 41.8744C48.4029 42.0128 48.3932 42.1551 48.3952 42.2937C48.362 42.0635 48.3211 41.8354 48.2723 41.6073C48.0325 40.4724 47.6094 39.3551 46.8722 38.462C46.1333 37.569 45.051 36.9216 43.8927 36.8826C42.4362 36.8318 41.0907 37.7366 40.1821 38.8754C39.2734 40.0141 38.7119 41.383 38.0606 42.6856C38.045 42.7167 38.0294 42.7479 38.0118 42.7792C38.977 38.035 38.9712 33.0764 37.9825 28.3244C37.731 27.1135 37.4112 25.9026 36.8322 24.8087C36.68 24.5201 36.5065 24.2394 36.3154 23.9721C37.2533 22.3206 38.9361 21.0512 40.8256 20.8503C42.992 20.6203 45.1349 21.8058 46.4978 23.5041C47.0283 24.1671 47.4553 24.8964 47.8024 25.6686Z"
        fill="#FFC271"
      />
      <path
        d="M46.1564 40.1467C46.8643 40.8897 46.9735 42.0128 46.9149 43.0384L46.913 43.0521C45.4018 42.7577 43.8322 42.7577 42.321 43.0521L42.3131 43.0189C42.0265 42.3346 42.0519 41.4024 42.3951 40.7454C42.7383 40.0882 43.3953 39.6008 44.1265 39.4779C44.8578 39.357 45.6456 39.6105 46.1564 40.1467Z"
        fill="white"
      />
      <path
        d="M36.7023 43.9374C36.9071 43.1652 36.5521 42.2897 35.8873 41.8451C35.2242 41.4005 34.3078 41.3948 33.6136 41.7906C32.9215 42.1863 32.4691 42.9547 32.4105 43.7521C33.8496 43.8126 35.2886 43.875 36.7023 43.9374ZM36.9481 44.5653C35.6591 45.9419 33.6078 46.0492 32.1531 45.5695C31.0905 45.2185 30.0668 44.0095 29.5929 43.1321C29.3121 42.6095 29.1113 41.9661 28.9475 41.2855C29.3179 39.595 29.5188 37.7815 29.5188 35.896C29.5188 31.5573 28.4561 27.6029 26.7109 24.6274C27.2843 23.9352 27.9413 23.319 28.694 22.8236C30.332 21.7512 32.5139 21.3554 34.2805 22.2036C35.1014 22.5975 35.7839 23.2293 36.3163 23.9721C36.5073 24.2394 36.6808 24.5201 36.833 24.8087C37.4121 25.9026 37.7319 27.1135 37.9834 28.3244C38.972 33.0764 38.9779 38.035 38.0126 42.7792V42.783C37.7007 43.3992 37.3575 44.0095 36.9519 44.5672L36.9481 44.5653Z"
        fill="#FFC271"
      />
      <path
        d="M35.8879 41.8453C36.5528 42.2899 36.9077 43.1654 36.7029 43.9376C35.2892 43.8752 33.8502 43.8127 32.4111 43.7523C32.4697 42.9548 32.9221 42.1865 33.6142 41.7907C34.3085 41.3949 35.2249 41.4007 35.8879 41.8453Z"
        fill="white"
      />
      <path
        d="M16.3923 35.2038C16.5171 32.4057 15.702 30.0989 14.571 30.0482C14.5164 30.0463 14.4599 30.0482 14.4072 30.058C13.5181 30.2901 12.7127 32.7704 12.5879 34.8821C12.4593 37.0952 13.0793 39.8368 13.9335 40.1469C13.9958 40.1644 14.0602 40.1761 14.1245 40.178C15.2535 40.2269 16.2695 37.9999 16.3923 35.2038ZM29.5191 35.896C29.5191 37.7815 29.3183 39.5951 28.9478 41.2855C27.8344 46.3691 25.1923 50.3585 21.8677 51.9322C20.8654 52.4099 19.8007 52.6653 18.6971 52.6653C12.7206 52.6653 7.875 45.1581 7.875 35.896C7.875 26.6359 12.7206 19.1267 18.6971 19.1267C21.8735 19.1267 24.732 21.2482 26.7112 24.6275C28.4564 27.603 29.5191 31.5574 29.5191 35.896Z"
        fill="#6CBEE3"
      />
      <path
        d="M14.5713 30.0482C15.7022 30.0989 16.5173 32.4057 16.3925 35.2038C16.2696 37.9999 15.2538 40.2267 14.1248 40.178C14.0604 40.1761 13.9961 40.1644 13.9336 40.1468C13.0796 39.8368 12.4595 37.0952 12.5882 34.8821C12.713 32.7702 13.5183 30.2899 14.4075 30.0579C14.4601 30.0482 14.5167 30.0462 14.5713 30.0482Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2274 13.9868C26.9684 15.7516 27.7113 17.5164 28.4543 19.2812C28.5395 19.4835 28.7729 19.5786 28.9753 19.4934C29.1776 19.4082 29.2727 19.1748 29.1876 18.9725C28.4447 17.2079 27.7018 15.4433 26.9609 13.6788C26.8759 13.4764 26.6426 13.381 26.4401 13.466C26.2377 13.551 26.1424 13.7844 26.2274 13.9868Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7772 11.0677C40.7401 12.9435 40.7011 14.8174 40.6641 16.6932C40.6598 16.9127 40.8345 17.0944 41.054 17.0987C41.2735 17.103 41.4552 16.9284 41.4596 16.7088C41.4966 14.8331 41.5355 12.9592 41.5726 11.0834C41.577 10.8639 41.4023 10.6822 41.1828 10.6778C40.9633 10.6735 40.7815 10.8482 40.7772 11.0677Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.9834 14.0889L51.5792 18.2773C51.4699 18.4677 51.5357 18.711 51.7261 18.8203C51.9165 18.9296 52.1598 18.8638 52.2692 18.6734L54.6734 14.485C54.7827 14.2946 54.7168 14.0513 54.5264 13.942C54.336 13.8327 54.0927 13.8985 53.9834 14.0889Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1509 42.3363C48.1795 42.5172 48.2023 42.7 48.2213 42.8827C48.4534 45.1573 48.0353 47.4891 47.226 49.6371C46.2611 52.1951 44.7613 54.5182 43.1256 56.7068C43.0435 56.8167 43.066 56.9727 43.1759 57.0548C43.2858 57.1369 43.4418 57.1144 43.5239 57.0045C45.1874 54.7785 46.71 52.4141 47.6913 49.8126C47.6913 49.8126 47.6913 49.8125 47.6913 49.8125C48.5276 47.5927 48.9558 45.1825 48.7159 42.8319C48.7159 42.8318 48.7159 42.8316 48.7159 42.8314C48.6959 42.6399 48.672 42.4484 48.6421 42.2588C48.6207 42.1233 48.4933 42.0306 48.3578 42.052C48.2222 42.0734 48.1296 42.2008 48.1509 42.3363Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2289 42.9012C38.2486 42.8664 38.266 42.8317 38.2834 42.7968C38.9269 41.5097 39.479 40.1556 40.3769 39.0304C41.2375 37.952 42.5051 37.083 43.8846 37.1311C43.8847 37.1311 43.8848 37.1311 43.8849 37.1311C44.973 37.1677 45.9868 37.7814 46.6811 38.6205C47.3927 39.4827 47.798 40.5629 48.0295 41.6587C48.0296 41.6589 48.0296 41.659 48.0297 41.6592C48.0772 41.8819 48.1172 42.1045 48.1495 42.3291C48.1691 42.4649 48.2952 42.5593 48.431 42.5398C48.5668 42.5202 48.6612 42.3941 48.6417 42.2582C48.6078 42.0227 48.5659 41.7893 48.516 41.5558C48.2679 40.3819 47.8269 39.2276 47.0645 38.3038C47.0644 38.3038 47.0644 38.3037 47.0643 38.3036C46.2805 37.3565 45.13 36.6755 43.9017 36.6341C42.3681 36.5808 40.945 37.5213 39.9882 38.7203C39.9882 38.7203 39.9882 38.7203 39.9882 38.7203C39.0687 39.8726 38.4977 41.2563 37.8387 42.5744C37.8249 42.602 37.8113 42.6295 37.7957 42.6571C37.7283 42.7766 37.7707 42.9284 37.8902 42.9957C38.0098 43.0631 38.1615 43.0208 38.2289 42.9012Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5584 50.1116C29.8756 48.9784 30.9729 48.2469 32.0603 47.7959C32.0604 47.7958 32.0605 47.7958 32.0607 47.7957C33.232 47.3077 34.5005 47.0152 35.5533 46.3061C35.5534 46.306 35.5535 46.306 35.5536 46.3059C36.184 45.8802 36.706 45.3297 37.154 44.7134C37.5674 44.1451 37.9175 43.5233 38.2355 42.8954C38.2975 42.7729 38.2484 42.6232 38.126 42.5612C38.0036 42.4993 37.8538 42.5483 37.7919 42.6708C37.4859 43.2751 37.1497 43.874 36.7518 44.421C36.7518 44.421 36.7518 44.421 36.7518 44.421C36.338 44.9903 35.8577 45.5005 35.2754 45.8938C34.2497 46.5846 33.0106 46.8613 31.8694 47.3367C30.6444 47.8448 29.437 48.7005 29.0796 49.9775C29.0426 50.1096 29.1198 50.247 29.252 50.2839C29.3841 50.3209 29.5214 50.2437 29.5584 50.1116Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5712 47.4345C42.0015 48.5855 43.9071 49.1296 45.7298 48.9051C45.866 48.8883 45.9629 48.7642 45.9462 48.628C45.9294 48.4918 45.8052 48.3948 45.669 48.4116C43.9779 48.6199 42.2099 48.115 40.883 47.0471C40.7761 46.961 40.6194 46.978 40.5334 47.0849C40.4474 47.1918 40.4643 47.3484 40.5712 47.4345Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.472 49.8805C42.3326 50.4256 43.3572 50.7083 44.3756 50.6775C44.5128 50.6733 44.6207 50.5586 44.6166 50.4214C44.6124 50.2843 44.4977 50.1763 44.3606 50.1805C43.4407 50.2083 42.5154 49.9528 41.7381 49.4605C41.6222 49.387 41.4684 49.4215 41.395 49.5375C41.3216 49.6534 41.3561 49.8071 41.472 49.8805Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2328 42.8639C47.1413 42.844 47.0519 42.826 46.9605 42.8082C46.9605 42.8082 46.9604 42.8082 46.9604 42.8082C45.4179 42.5076 43.8157 42.5076 42.2733 42.8082C42.0384 42.854 41.8036 42.9057 41.5707 42.9654C41.4378 42.9995 41.3576 43.1351 41.3916 43.268C41.4257 43.4009 41.5613 43.4812 41.6942 43.4471C41.9176 43.3898 42.143 43.3402 42.3684 43.2962C43.8482 43.0079 45.3854 43.0079 46.8653 43.2962C46.9532 43.3134 47.0392 43.3307 47.1272 43.3498C47.2613 43.3789 47.3938 43.2937 47.4229 43.1596C47.4521 43.0255 47.3669 42.893 47.2328 42.8639Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5424 42.9229C42.2824 42.302 42.3041 41.4564 42.6153 40.8603C42.923 40.2712 43.5117 39.8333 44.1674 39.723C44.8189 39.6155 45.5212 39.8404 45.9764 40.3182C46.6376 41.0121 46.7213 42.0661 46.6667 43.0242C46.6589 43.1612 46.7637 43.2788 46.9007 43.2866C47.0377 43.2944 47.1553 43.1895 47.1631 43.0525C47.2254 41.9593 47.0908 40.767 46.3364 39.9752C45.77 39.3806 44.8968 39.0985 44.086 39.2325C44.0857 39.2325 44.0855 39.2326 44.0853 39.2326C43.2786 39.3682 42.5532 39.9053 42.1746 40.6301C42.1746 40.6302 42.1745 40.6302 42.1745 40.6302C41.7996 41.3483 41.7706 42.367 42.0838 43.1149C42.1368 43.2415 42.2826 43.3012 42.4091 43.2482C42.5357 43.1952 42.5954 43.0494 42.5424 42.9229Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7699 42.7303C37.7659 42.7458 37.7629 42.7613 37.7603 42.7769C37.7557 42.8042 37.7556 42.832 37.7602 42.8593C37.761 42.8667 37.7632 42.8848 37.7652 42.895C37.7913 43.0297 37.9219 43.1179 38.0566 43.0918C38.1822 43.0674 38.2673 42.9522 38.257 42.8276C39.2287 38.051 39.2227 33.0584 38.2273 28.274C37.9711 27.0404 37.6433 25.8074 37.0534 24.693C36.8961 24.3946 36.7166 24.1041 36.519 23.8279C36.519 23.8278 36.5189 23.8277 36.5189 23.8277C35.9622 23.0507 35.2471 22.3916 34.3886 21.9798C32.5453 21.0947 30.2674 21.497 28.5583 22.616C28.5582 22.6161 28.558 22.6162 28.5579 22.6163C27.7845 23.1251 27.109 23.7578 26.52 24.469C26.4325 24.5747 26.4472 24.7316 26.5529 24.8191C26.6586 24.9066 26.8154 24.8919 26.9029 24.7862C27.4604 24.113 28.099 23.5134 28.831 23.0318C30.3978 22.006 32.4836 21.6166 34.1734 22.428C34.1734 22.428 34.1735 22.4281 34.1735 22.4281C34.9568 22.8038 35.6067 23.4083 36.1146 24.1172C36.2991 24.3751 36.4667 24.6463 36.6136 24.925C36.6137 24.9251 36.6137 24.9252 36.6138 24.9253C37.1819 25.9985 37.4937 27.1872 37.7405 28.3752C37.7405 28.3752 37.7405 28.3753 37.7405 28.3753C38.7222 33.0943 38.7281 38.0186 37.7695 42.7298L37.7699 42.7303ZM38.2504 42.8568L38.2501 42.8344L38.1525 42.9141C38.0858 42.9755 38.0132 43.032 38.0132 43.032L38.249 42.862L38.2504 42.8568ZM37.9888 42.9982L38.01 43.0276L38.0132 43.028L37.9888 42.9982ZM38.0095 43.0275C37.9791 42.9895 37.7645 42.7246 37.7645 42.7795C37.7645 42.9893 37.9789 43.0237 38.0095 43.0275Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6243 51.9843C22.4483 55.9621 24.1279 59.7772 26.8285 62.7911C31.0521 67.5046 37.7114 69.9068 43.9718 68.9724C43.9718 68.9724 43.9718 68.9724 43.9718 68.9724C50.2321 68.0361 55.8997 63.7947 58.5608 58.0519C59.916 55.1301 60.4717 51.8648 60.2505 48.6545C60.2505 48.6545 60.2505 48.6545 60.2505 48.6544C60.2129 48.1116 60.1537 47.5708 60.0728 47.0319C60.0727 47.0318 60.0727 47.0316 60.0727 47.0315C59.6973 44.5602 58.8557 42.1623 57.5619 40.0228C57.5298 39.9687 57.4976 39.9145 57.4635 39.8602C57.3905 39.744 57.2369 39.709 57.1208 39.782C57.0046 39.855 56.9695 40.0086 57.0425 40.1248C57.0744 40.1756 57.1045 40.2263 57.1345 40.277C57.1349 40.2777 57.1354 40.2784 57.1358 40.2791C58.3961 42.363 59.2154 44.6988 59.5811 47.106C59.66 47.6316 59.7178 48.1592 59.7544 48.6887C59.9699 51.8158 59.4298 54.9967 58.1097 57.8427C58.1097 57.8428 58.1096 57.8428 58.1096 57.8428C55.5175 63.4369 49.9966 67.5685 43.8983 68.4806C37.8001 69.3908 31.313 67.0508 27.1988 62.4593C24.5565 59.5104 22.9174 55.7754 22.1113 51.8834C22.0834 51.749 21.9517 51.6626 21.8174 51.6904C21.683 51.7182 21.5965 51.8499 21.6243 51.9843Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.8104 43.7997C54.0673 43.7962 54.2999 43.6236 54.3651 43.3769C54.5376 42.7289 54.2841 41.9849 53.7887 41.5163C53.2516 41.0069 52.4847 40.7947 51.7453 40.7969C50.7654 40.7991 49.7256 41.211 49.2722 42.1006C49.1781 42.2836 49.1324 42.4942 49.1665 42.699C49.1666 42.6994 49.1667 42.6997 49.1667 42.7001C49.2499 43.1855 49.6561 43.33 50.043 43.4052C50.043 43.4052 50.0431 43.4052 50.0432 43.4052C51.2903 43.6464 52.5494 43.8194 53.8104 43.7997ZM53.8108 43.7997H53.8103H53.8108ZM53.8035 43.3026C53.8034 43.3026 53.8032 43.3026 53.803 43.3026C52.5761 43.3218 51.3511 43.1517 50.1377 42.9171C50.0391 42.8979 49.9352 42.8768 49.8433 42.8367C49.754 42.7978 49.6777 42.7367 49.6569 42.6167C49.6405 42.5173 49.669 42.4163 49.7148 42.3272C49.715 42.3269 49.7151 42.3267 49.7152 42.3264C50.0847 41.6014 50.9478 41.2959 51.7464 41.2941C51.7465 41.2941 51.7466 41.2941 51.7466 41.2941C52.3601 41.2923 53.0011 41.4546 53.4467 41.8772C53.4468 41.8772 53.4468 41.8773 53.4469 41.8774C53.8132 42.2238 54.0121 42.7702 53.8845 43.2493C53.8845 43.2495 53.8844 43.2497 53.8843 43.2499C53.8753 43.284 53.8391 43.3021 53.8035 43.3026Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6926 44.1858C36.8093 44.1909 36.9139 44.1141 36.9438 44.0011C37.1749 43.1296 36.7772 42.1403 36.0268 41.6386C35.2875 41.1429 34.2658 41.1332 33.4917 41.5746C33.4916 41.5746 33.4915 41.5747 33.4914 41.5747C32.729 42.0107 32.2283 42.8555 32.1638 43.7339C32.1588 43.801 32.1813 43.8673 32.2261 43.9175C32.2708 43.9678 32.334 43.9977 32.4013 44.0005C33.8402 44.061 35.279 44.1234 36.6926 44.1858ZM36.501 43.6796C35.2448 43.6243 33.9697 43.569 32.6933 43.5151C32.8119 42.8934 33.1905 42.3196 33.7381 42.0064C34.3524 41.6563 35.1633 41.6582 35.75 42.0516C35.7501 42.0517 35.7502 42.0518 35.7503 42.0518C36.2745 42.4023 36.5798 43.0593 36.501 43.6796Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5019 24.1463C36.5106 24.1331 36.5263 24.1045 36.5263 24.1045C36.5263 24.1045 36.5239 24.1085 36.5226 24.1104C36.526 24.1054 36.5292 24.1002 36.5322 24.0949C37.4312 22.5117 39.0411 21.2901 40.8523 21.0975C42.9363 20.8763 44.9932 22.0259 46.3044 23.6597C46.8216 24.3061 47.2377 25.0176 47.5762 25.7705C47.5762 25.7706 47.5763 25.7708 47.5764 25.7709C48.1142 26.9618 48.4572 28.251 48.6973 29.538V29.538C49.4548 33.596 49.279 37.8279 48.1871 41.8086C48.1508 41.9409 48.2288 42.0778 48.3611 42.1141C48.4934 42.1504 48.6303 42.0724 48.6666 41.9401C49.7783 37.8872 49.9573 33.5784 49.1861 29.4468C48.9387 28.1209 48.5836 26.7933 48.0295 25.5663C47.6739 24.7752 47.236 24.0281 46.6925 23.3489C46.6924 23.3488 46.6924 23.3487 46.6923 23.3486C45.2774 21.5855 43.0487 20.3643 40.7998 20.603C40.7998 20.603 40.7998 20.603 40.7998 20.6031C38.8321 20.8122 37.0765 22.1295 36.0998 23.8493L36.1094 23.8338C36.1013 23.8459 36.0951 23.86 36.0871 23.8721C36.0114 23.9866 36.0429 24.1409 36.1574 24.2166C36.2719 24.2923 36.4262 24.2608 36.5019 24.1463Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6011 39.4787L57.3608 39.1749C57.3342 39.2874 57.3056 39.3979 57.2751 39.5103C57.2749 39.5109 57.2747 39.5116 57.2745 39.5122C57.2249 39.7013 57.1714 39.8885 57.1122 40.0757C57.1119 40.0768 57.1115 40.0778 57.1112 40.0789C56.8317 41.0049 56.4582 41.9066 55.9202 42.7089C55.92 42.7091 55.9199 42.7092 55.9198 42.7094C55.2874 43.6572 54.4053 44.4641 53.34 44.867C52.2968 45.2616 51.0688 45.2341 50.115 44.6587C49.4375 44.2477 48.8948 43.5592 48.711 42.7987C48.6788 42.6654 48.5443 42.5832 48.411 42.6155C48.2776 42.6477 48.1955 42.7822 48.2277 42.9155C48.4417 43.8008 49.0687 44.6057 49.8574 45.084C49.8575 45.0841 49.8577 45.0842 49.8579 45.0843C50.9399 45.7372 52.3324 45.7798 53.5159 45.3321C54.6773 44.8928 55.6437 44.019 56.3332 42.9857C56.8984 42.1429 57.2931 41.1968 57.5868 40.2241C57.6482 40.0299 57.7037 39.8357 57.7552 39.6396C57.787 39.5222 57.8169 39.4069 57.8446 39.2895L57.625 39.4632L57.6416 39.45L57.6795 39.4187L57.8442 39.2893C57.9668 38.7874 58.0714 38.2816 58.1525 37.7718C58.174 37.6363 58.0815 37.5088 57.946 37.4872C57.8104 37.4657 57.6829 37.5582 57.6614 37.6937C57.5825 38.1902 57.4805 38.6826 57.3612 39.1714L57.6027 39.479L57.6011 39.4787ZM57.601 39.4787C57.579 39.4515 57.3541 39.1744 57.3541 39.2304C57.3541 39.4453 57.5789 39.4762 57.601 39.4787Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4357 30.2252C57.756 31.3895 57.8915 32.6075 57.9221 33.8178C57.9549 35.1125 57.8722 36.4109 57.6603 37.6902C57.6378 37.8256 57.7295 37.9537 57.8649 37.9761C58.0003 37.9986 58.1284 37.9069 58.1508 37.7715C58.3679 36.4612 58.4528 35.1313 58.4192 33.8052C58.3876 32.5547 58.246 31.2963 57.9151 30.0934C57.8787 29.9611 57.7418 29.8832 57.6095 29.9196C57.4772 29.956 57.3993 30.093 57.4357 30.2252Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6232 25.4964C47.601 25.5191 47.586 25.542 47.5767 25.5647C47.5722 25.5743 47.5684 25.5841 47.5653 25.5941C47.5565 25.6151 47.5518 25.6423 47.5518 25.6706C47.5518 25.8079 47.6632 25.9192 47.8004 25.9192L47.8053 25.9172C47.8701 25.9165 47.9321 25.8904 47.978 25.8446L47.9781 25.8445L47.978 25.8446L47.9804 25.8422C49.2213 24.5698 51.2529 24.2534 52.9328 24.8369C53.314 24.9696 53.6785 25.1435 54.0204 25.3509C54.0205 25.3509 54.0206 25.351 54.0206 25.351C55.2145 26.0733 56.1634 27.2024 56.7803 28.4659L56.7812 28.468C56.7833 28.4734 56.7857 28.4787 56.7882 28.4839C57.0602 29.0431 57.2731 29.6252 57.4348 30.2223C57.4707 30.3548 57.6073 30.4332 57.7398 30.3973C57.8722 30.3615 57.9506 30.2248 57.9148 30.0924C57.7449 29.4651 57.5211 28.8538 57.2354 28.2664L57.2358 28.2675L57.2351 28.2659C57.2328 28.26 57.2302 28.2542 57.2275 28.2485C56.569 26.8995 55.5527 25.6968 54.2781 24.9256C53.9065 24.7003 53.5104 24.5114 53.0962 24.3673C53.0961 24.3673 53.0961 24.3672 53.096 24.3672C51.2388 23.7222 48.9963 24.0884 47.6244 25.4951L47.6232 25.4964ZM47.8004 25.9192L47.7999 25.9172H47.7998L47.8004 25.9192ZM47.7994 25.9155C47.7881 25.8707 47.678 25.4414 47.6265 25.4929L47.6232 25.4964C47.6036 25.5168 47.588 25.5399 47.5767 25.5647L47.5741 25.5711C47.573 25.5743 47.572 25.5775 47.5709 25.5808L47.57 25.5842C47.6067 25.5153 47.6956 25.5415 47.7993 25.9154L47.7994 25.9155Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1477 42.2937L48.1477 42.2939L48.1477 42.2943V42.2967L48.1477 42.2976C48.1477 42.4349 48.2591 42.5463 48.3963 42.5463L48.4014 42.5423C48.4661 42.541 48.5278 42.5145 48.5734 42.4683C48.6191 42.4219 48.6447 42.3595 48.6448 42.2945L48.6449 42.2939L48.6449 42.2916V42.2902C48.6431 42.1667 48.6517 42.0396 48.6726 41.916C48.6956 41.7808 48.6044 41.6523 48.4691 41.6294C48.3338 41.6064 48.2053 41.6977 48.1824 41.8329C48.1566 41.985 48.1457 42.1415 48.1477 42.2937ZM48.1483 42.3106C48.1512 42.3627 48.1678 42.4031 48.1913 42.4345C48.1668 42.3988 48.1513 42.3564 48.1483 42.3106ZM48.1477 42.2937V42.2943L48.1477 42.2955L48.1478 42.2918L48.1477 42.2937Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9741 52.1565C25.3552 50.5559 28.0579 46.5086 29.1902 41.3387C29.5643 39.6314 29.7673 37.8001 29.7673 35.8959C29.7673 31.5087 28.6901 27.5105 26.9253 24.5016C24.8959 21.0368 21.9536 18.8779 18.6967 18.8779C16.2378 18.8779 13.9609 20.1056 12.1188 22.1981C9.39928 25.2873 7.62598 30.2714 7.62598 35.8959C7.62598 41.5216 9.39928 46.5053 12.1188 49.5941C13.9609 51.6864 16.2378 52.9137 18.6967 52.9137C19.8375 52.9137 20.9381 52.6503 21.9741 52.1565ZM21.7608 51.7073C21.7606 51.7074 21.7604 51.7075 21.7602 51.7076C20.7918 52.1692 19.7631 52.4165 18.6967 52.4165C16.3743 52.4165 14.2319 51.2416 12.492 49.2656C9.83867 46.2519 8.1232 41.3847 8.1232 35.8959C8.1232 30.4082 9.83867 25.5407 12.492 22.5267C14.2319 20.5504 16.3743 19.3752 18.6967 19.3752C21.7925 19.3752 24.5673 21.4594 26.4963 24.753C26.4963 24.753 26.4964 24.753 26.4964 24.7531C28.222 27.6952 29.2701 31.606 29.2701 35.8959C29.2701 37.7629 29.0713 39.5584 28.7045 41.2323C28.7045 41.2323 28.7045 41.2323 28.7045 41.2323C27.6101 46.2289 25.0287 50.1606 21.7608 51.7073Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3637 48.7397L21.1921 48.3159C19.9142 49.5543 18.4308 50.2717 16.8451 50.2717C16.4246 50.2717 16.0113 50.2215 15.6074 50.1229C15.4741 50.0903 15.3395 50.1721 15.3069 50.3054C15.2744 50.4387 15.3562 50.5734 15.4895 50.6059C15.9319 50.7139 16.3845 50.7689 16.8451 50.7689C18.5549 50.7689 20.1602 50.0083 21.5381 48.6729L21.3651 48.743L21.3704 48.7389L21.5383 48.6708C24.2376 46.0505 26.0634 41.1899 26.0634 35.6229C26.0634 30.62 24.5912 26.1877 22.335 23.4405C20.7984 21.5695 18.8968 20.475 16.8451 20.475C16.3845 20.475 15.9319 20.5301 15.4895 20.6381C15.3562 20.6707 15.2744 20.8053 15.3069 20.9386C15.3395 21.0719 15.4741 21.1537 15.6074 21.1211C16.0113 21.0225 16.4246 20.9722 16.8451 20.9722C18.7573 20.9722 20.5187 22.0123 21.9508 23.7561C24.1477 26.4311 25.5662 30.7515 25.5662 35.6229C25.5662 41.0341 23.8157 45.7671 21.1919 48.314L21.3651 48.741L21.3637 48.7397Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.9187 48.9062C60.661 49.1691 61.4336 49.3065 62.2285 49.3065C64.4828 49.3065 66.5701 48.1944 68.2587 46.2981C70.7488 43.5016 72.373 38.9899 72.373 33.8973C72.373 28.8046 70.7488 24.2929 68.2587 21.4964C66.5701 19.6001 64.4828 18.488 62.2285 18.488C61.6134 18.488 61.0104 18.571 60.4257 18.7308C57.7533 19.4553 55.4335 21.7741 53.9244 25.0338C53.8668 25.1583 53.9211 25.3062 54.0456 25.3638C54.1701 25.4215 54.318 25.3672 54.3756 25.2426C55.8149 22.1339 58.0072 19.9017 60.5563 19.2106C60.5564 19.2105 60.5566 19.2105 60.5568 19.2104C61.099 19.0622 61.6581 18.9853 62.2285 18.9853C64.3468 18.9853 66.3006 20.0452 67.8873 21.8271C70.3101 24.5479 71.8757 28.9424 71.8757 33.8973C71.8757 38.8521 70.3101 43.2466 67.8873 45.9674C66.3006 47.7493 64.3468 48.8092 62.2285 48.8092C61.4908 48.8092 60.7737 48.6815 60.0847 48.4374C59.9554 48.3916 59.8132 48.4594 59.7674 48.5888C59.7216 48.7181 59.7894 48.8603 59.9187 48.9062Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.2409 47.5673L61.2673 47.5668C63.1462 47.4664 64.8357 46.3831 66.1534 44.6139C68.0852 42.0203 69.2201 37.9417 68.9803 33.4181C68.7404 28.8956 67.1802 24.9608 64.9847 22.5869C63.4882 20.9688 61.6951 20.0709 59.8177 20.169C59.7964 20.1694 59.7706 20.1721 59.7479 20.1754C59.6121 20.1952 59.518 20.3215 59.5378 20.4573C59.5576 20.5931 59.6839 20.6872 59.8197 20.6674C59.8219 20.6671 59.8244 20.6661 59.8267 20.6661C59.8311 20.6661 59.8354 20.666 59.8398 20.6658C61.5799 20.5735 63.2327 21.4248 64.6197 22.9245C66.7509 25.229 68.2509 29.0542 68.4837 33.4444C68.7165 37.8358 67.6299 41.799 65.7546 44.3169C64.5358 45.9533 62.9848 46.9747 61.2474 47.0699C61.2236 47.0702 61.1979 47.0712 61.1752 47.0745C61.0395 47.0944 60.9453 47.2207 60.9651 47.3565C60.9847 47.4902 61.1075 47.5835 61.2409 47.5673Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4524 30.3024C14.4872 30.296 14.5247 30.2954 14.5608 30.2966C14.9402 30.3143 15.2525 30.6614 15.5137 31.1893C15.9669 32.1052 16.2164 33.5637 16.1438 35.1928C16.1438 35.1928 16.1438 35.1928 16.1438 35.1929C16.0722 36.8209 15.6947 38.2515 15.1627 39.124C14.8558 39.6273 14.5142 39.946 14.1351 39.9297C14.134 39.9296 14.1328 39.9296 14.1317 39.9295C14.0876 39.9282 14.0435 39.9196 14.0007 39.9075C13.8686 39.8703 13.7312 39.9473 13.6939 40.0794C13.6567 40.2114 13.7337 40.3489 13.8658 40.3861C13.9474 40.4091 14.0315 40.4239 14.1155 40.4265C14.6392 40.4482 15.1634 40.0781 15.5872 39.3829C16.1517 38.457 16.5646 36.9422 16.6405 35.2148C16.7176 33.4862 16.4402 31.9407 15.9593 30.9688C15.5979 30.2382 15.1072 29.8234 14.582 29.7999C14.5813 29.7998 14.5806 29.7998 14.5799 29.7998C14.5072 29.7971 14.4319 29.8005 14.3618 29.8135C14.2269 29.8385 14.1376 29.9684 14.1626 30.1033C14.1876 30.2382 14.3175 30.3274 14.4524 30.3024Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0179 39.9129C13.8065 39.8361 13.6339 39.562 13.4687 39.1901C13.0139 38.1659 12.7482 36.4048 12.8359 34.8963C12.9089 33.66 13.2196 32.2938 13.645 31.35C13.8059 30.9933 13.9811 30.6988 14.1686 30.503C14.2674 30.3998 14.366 30.3254 14.4697 30.2983C14.6025 30.2637 14.6821 30.1278 14.6475 29.995C14.6128 29.8622 14.4769 29.7825 14.3442 29.8172C14.03 29.8992 13.7081 30.2022 13.4307 30.6776C12.8661 31.6453 12.4292 33.3484 12.3395 34.8671C12.3395 34.8672 12.3395 34.8673 12.3395 34.8674C12.247 36.4573 12.5349 38.3124 13.0143 39.3919C13.2496 39.9217 13.5471 40.2709 13.8482 40.3803C13.9772 40.4271 14.1199 40.3604 14.1668 40.2314C14.2136 40.1025 14.1469 39.9597 14.0179 39.9129Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5988 29.5896C15.6907 29.5706 15.7827 29.5663 15.8761 29.571C16.4217 29.6031 16.8897 30.0174 17.2617 30.6611C17.8756 31.7233 18.2085 33.4012 18.0984 35.2631C18.0984 35.2632 18.0984 35.2632 18.0984 35.2633C17.9972 36.9892 17.5351 38.5133 16.8827 39.5106C16.4792 40.1274 16.0097 40.5406 15.5027 40.6355C15.3678 40.6608 15.2788 40.7908 15.3041 40.9257C15.3294 41.0606 15.4593 41.1495 15.5942 41.1243C16.2119 41.0086 16.8072 40.5343 17.2988 39.7828C17.989 38.7278 18.4877 37.1183 18.5948 35.2924C18.7116 33.3169 18.3435 31.5393 17.6922 30.4123C17.2209 29.5968 16.5953 29.1147 15.9041 29.0746C15.9035 29.0745 15.9029 29.0745 15.9023 29.0745C15.7661 29.0675 15.6321 29.075 15.4981 29.1027C15.3638 29.1304 15.2772 29.2621 15.305 29.3964C15.3328 29.5308 15.4644 29.6174 15.5988 29.5896Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6753 30.4078L57.6754 30.4079H57.6774L57.6789 30.4051C57.7092 30.3815 57.924 30.212 57.924 30.1573C57.924 29.9939 57.7939 30.1969 57.722 30.3218C57.79 30.1796 57.8902 29.9106 57.6769 29.9106C57.563 29.9106 57.5877 30.0964 57.6243 30.2409C57.5888 30.1454 57.5561 30.0904 57.528 30.0636C57.4666 30.0023 57.4268 30.0752 57.4268 30.1573V30.1573C57.4268 30.2264 57.455 30.289 57.5006 30.334C57.5452 30.3792 57.607 30.4073 57.6753 30.4078Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6582 37.7308L57.6582 37.7317L57.6582 37.7327C57.6582 37.7385 57.6584 37.7443 57.6589 37.75L57.6589 37.7514L57.6591 37.7533L57.6595 37.7581L57.6604 37.7655C57.6641 37.796 57.6728 37.8222 57.6848 37.8446C57.7257 37.9257 57.8098 37.9814 57.9068 37.9814C57.9068 37.9814 58.1554 37.7896 58.1554 37.7308C58.1554 37.5048 57.9068 37.9794 57.9068 37.9794C57.7696 37.4821 57.6582 37.5935 57.6582 37.7308ZM57.6588 37.7464L57.6589 37.75L57.6591 37.7533L57.6604 37.7655C57.6641 37.7936 57.6725 37.8203 57.6848 37.8446L57.6887 37.8518C57.6915 37.8562 57.6943 37.8606 57.6971 37.865L57.7038 37.874C57.6779 37.8375 57.6616 37.7937 57.6588 37.7464ZM57.6582 37.7308L57.6582 37.7316L57.6583 37.7297L57.6582 37.7308Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.5846 38.9842C57.5821 38.9837 57.5795 38.9836 57.5768 38.9836C57.5251 38.9836 57.4908 38.9977 57.4696 39.0213C57.4325 39.0445 57.4021 39.0771 57.3816 39.1158C57.3464 39.1176 57.3253 39.1725 57.3253 39.2322C57.3253 39.3694 57.4367 39.4808 57.5739 39.4808L57.5991 39.4808L57.6013 39.4808V39.4808L57.6073 39.4807C58.4106 39.4614 59.9615 39.566 59.9615 39.566C59.9844 39.5676 60.0074 39.566 60.0298 39.5612C60.1722 39.5309 60.3297 39.4388 60.4869 39.2798C60.6735 39.091 60.8712 38.7996 61.0627 38.4344C61.7717 37.0827 62.4204 34.689 62.293 33.0319C62.293 33.0318 62.293 33.0317 62.293 33.0317C62.1809 31.5867 61.957 28.9934 59.6519 27.9234C59.6191 27.9081 59.5834 27.9003 59.5472 27.9003C59.1703 27.9003 58.3082 27.8979 57.6578 27.9736C57.3511 28.0092 57.0881 28.0652 56.9273 28.1397C56.8028 28.1974 56.7486 28.3453 56.8063 28.4698C56.864 28.5943 57.0119 28.6485 57.1364 28.5909C57.3107 28.5101 57.6352 28.469 57.9913 28.4409C58.5396 28.3977 59.1594 28.3974 59.4914 28.3975C61.5504 29.3891 61.6956 31.7603 61.7972 33.0699C61.9183 34.645 61.2962 36.9187 60.6224 38.2035C60.4583 38.5163 60.2931 38.7686 60.1332 38.9303C60.069 38.9952 60.0108 39.0457 59.9527 39.0672C59.6963 39.0506 58.335 38.9659 57.5953 38.9836L57.5846 38.9842Z"
        fill="#0A4027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7978 44.4666L36.767 44.3954C35.5484 45.6969 33.6068 45.7869 32.2316 45.3335C31.224 45.0007 30.2615 43.8462 29.8123 43.0142C29.5406 42.5087 29.3481 41.8857 29.1896 41.2273C29.1575 41.0939 29.0231 41.0117 28.8897 41.0438C28.7563 41.0759 28.674 41.2103 28.7062 41.3437C28.8754 42.0465 29.0844 42.7102 29.3744 43.2498C29.3745 43.25 29.3745 43.2501 29.3746 43.2503C29.873 44.1732 30.9578 45.4364 32.0757 45.8057C32.0757 45.8057 32.0758 45.8057 32.0758 45.8057C33.6098 46.3115 35.7707 46.1871 37.1299 44.7353L36.9485 44.814C36.9485 44.814 37.1971 44.6223 37.1971 44.5634C37.1971 44.3374 36.9485 44.812 36.9485 44.812C36.8919 44.607 36.8397 44.5054 36.7978 44.4666ZM36.7004 44.579C36.7032 44.633 36.7207 44.6747 36.7454 44.7067C36.7195 44.6702 36.7033 44.6264 36.7004 44.579ZM36.6998 44.5634L36.6999 44.5636L36.6998 44.5634V44.5634Z"
        fill="#0A4027"
      />
    </svg>
  );
};

export default PillarMove;
