import axios from "axios";
import { IDENTITY_SERVICE_API } from "../../config/api.config";
import { CLIENT_ID, CLIENT_SECRET } from "../../config/app.config";

function getUrlParams() {
  const params = new URLSearchParams();
  params.append("client_id", CLIENT_ID);
  params.append("client_secret", CLIENT_SECRET);
  return params;
}

function getHeaders() {
  return {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
}

export const login = async (email: string, password: string) => {
  const params = getUrlParams();
  params.append("grant_type", "password");
  params.append(
    "scope",
    "openid profile orgId status user content subscription sixpillarsreport offline_access"
  );
  params.append("username", email);
  params.append("password", password);

  const { data, status, statusText } = await axios.post(
    `${IDENTITY_SERVICE_API.BASE_URL}${IDENTITY_SERVICE_API.ENDPOINTS.TOKEN}`,
    params,
    getHeaders()
  );

  return {
    accessToken: data.access_token,
    expiry: data.expires_in,
    refreshToken: data.refresh_token,
    status,
    statusText,
  };
};

export const refreshLogin = async (refreshToken: string) => {
  const params = getUrlParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken);

  const { data, status, statusText } = await axios.post(
    `${IDENTITY_SERVICE_API.BASE_URL}${IDENTITY_SERVICE_API.ENDPOINTS.TOKEN}`,
    params,
    getHeaders()
  );

  return {
    accessToken: data.access_token,
    expiry: data.expires_in,
    refreshToken: data.refresh_token,
    status,
    statusText,
  };
};

export const getUserInfo = async (token: string) => {
  const { data, status, statusText } = await axios.post(
    `${IDENTITY_SERVICE_API.BASE_URL}${IDENTITY_SERVICE_API.ENDPOINTS.USERINFO}`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return {
    emailAddress: data.preferred_username,
    status,
    statusText,
  };
};

export const loginTokenExchange = async (token: string) => {
  const params = getUrlParams();
  params.append("grant_type", "token_exchange");
  params.append(
    "scope",
    "openid profile orgId status user content subscription sixpillarsreport offline_access"
  );
  params.append("token", token);

  const { data, status, statusText } = await axios.post(
    `${IDENTITY_SERVICE_API.BASE_URL}${IDENTITY_SERVICE_API.ENDPOINTS.TOKEN}`,
    params
  );

  return {
    accessToken: data.access_token,
    expiry: data.expires_in,
    refreshToken: data.refresh_token,
    status,
    statusText,
  };
};
