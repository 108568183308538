import groovColors from "../../styles/colors";

export const COUNT = "$count";
export const MORE = "more";
export const EVERY_DAY = "every day";
export const PILLAR_NAME = "{pillarName}";
export const PILLAR_BOXES = {
  AMAZING: {
    TITLE: "We think you’re super amazing for...",
    PART_ONE: "Making ",
    VARIABLE: `${COUNT} out of 6`,
    PART_TWO: " wellbeing pillars part of your everyday life!",
  },
  RECOMMEND: {
    TITLE: "We recommend",
    PART_ONE: "You should find ",
    VARIABLE: `some time for these ${COUNT} pillar(s)`,
    PART_TWO: " as they doesn’t seem to be part of your normal routine",
  },
  GOOD: {
    TITLE: "Keep up the good work!",
    PART_ONE: "You’ve managed to hit ",
    VARIABLE: `${COUNT} out of 6`,
    PART_TWO: " pillars some of the time! Well done!",
  },
};

export const PILLAR_CONTENT_BLOCKS = {
  A: {
    HIGHLIGHT: "Amazing! You're already a pro at this pillar.",
    MESSAGE:
      "You did all elements of the {pillarName} pillar often or every day. Go you good thing!",
    COLOR: groovColors.neutral[10],
  },
  B: {
    HIGHLIGHT: "Nice! You’re so close to clocking this pillar.",
    MESSAGE:
      "You did all elements of the {pillarName} pillar some of the time or more.",
    COLOR: groovColors.neutral[10],
  },
  C: {
    HIGHLIGHT:
      "Good start! A little more frequency will get this pillar humming.",
    MESSAGE:
      "You did all elements of the {pillarName} pillar some of the time.",
    COLOR: groovColors.neutral[10],
  },
  D: {
    HIGHLIGHT:
      "Showing promise! One element of this pillar could do with a boost.",
    MESSAGE:
      'There was one element of the {pillarName} pillar you did rarely or none of the time. Find out more on the <a href="/breakdown">Details</a> page.',
    COLOR: groovColors.neutral[10],
  },
  E: {
    HIGHLIGHT: "You could benefit from some focus here.",
    MESSAGE: "",
    COLOR: groovColors.orange[5],
  },
  F: {
    HIGHLIGHT: "You could benefit from some focus here.",
    MESSAGE: "",
    COLOR: groovColors.orange[5],
  },
};

export const PILLAR_DEFINITIONS = [
  {
    Pillar: "Chill",
    Definition:
      "Sounds like you could do with some more <strong>Chill</strong> in your day! Relaxing strengthens communication between your prefrontal cortex and amygdala, and stops the over-production of stress chemicals.",
    Link: "https://sharing.groovnow.com/shared/linkid-chill",
  },
  {
    Pillar: "Do",
    Definition:
      "A little more <strong>Do</strong> in your day will release dopamine, which is the brain’s reward chemical. Learning throughout life strengthens neural pathways and protects your brain from the effects of ageing.",
    Link: "https://sharing.groovnow.com/shared/linkid-do",
  },
  {
    Pillar: "Connect",
    Definition:
      "It’s worth taking some time to <strong>Connect</strong> with others as this stimulates the release of oxytocin, giving you that warm, fuzzy feeling. Strong connections are really important to human beings.",
    Link: "https://sharing.groovnow.com/shared/linkid-connect",
  },
  {
    Pillar: "Move",
    Definition:
      "You gotta <strong>Move</strong> to Groov! Regular exercise and taking small breaks from sitting are so good for you. Research shows movement actually protects against anxiety and depression.",
    Link: "https://sharing.groovnow.com/shared/linkid-move",
  },
  {
    Pillar: "Celebrate",
    Definition:
      "You are not alone, <strong>Celebrate</strong> is one of the most difficult pillars because we are generally hard on ourselves. But this pillar is really worth the effort. Self-kindness and gratitude provide dopamine boosts and rewire your brain for optimism.",
    Link: "https://sharing.groovnow.com/shared/linkid-celebrate",
  },
  {
    Pillar: "Enjoy",
    Definition:
      "Sounds like you need to take a bit of time to <strong>Enjoy</strong> yourself, go on – you deserve it! You’ll get a good dose of serotonin when you do things you enjoy and have things to look forward to.",
    Link: "https://sharing.groovnow.com/shared/linkid-enjoy",
  },
];

export const SIX_PILLARS_WELLBEING_DEFINITION_HELPTEXT = {
  Title: "6 Pillars of Wellbeing Definition",
  Content: [
    {
      Header: "Chill",
      Body: `<strong>Chill</strong> to strengthen communication between your prefrontal cortex and amygdala, and stop the overproduction of stress hormones, which can lead to burnout.
      <br><strong>Chill</strong> is all about reducing stress. It’s the things you do to calm your mind, relax your body, and switch off your fight or flight response.
      <br><a href="https://sharing.groovnow.com/shared/linkid-chill" target="_blank">Learn more</a>`,
    },
    {
      Header: "Do",
      Body: `<strong>Do</strong> to release dopamine and protect your brain from the effects of ageing. Learning throughout life strengthens neural pathways into old age.
      <br><strong>Do</strong> is all about learning, it's the things you do to keep your brain active and stay creative.
      <br><a href="https://sharing.groovnow.com/shared/linkid-do" target="_blank">Learn more</a>`,
    },
    {
      Header: "Connect",
      Body: `<strong>Connect</strong> to release oxytocin, which helps build trust and bond with others. Strong connections are linked with living longer and better emotional wellbeing.
      <br><strong>Connect</strong> is all about strengthening relationships, socialising, giving back and volunteering.
      <br><a href="https://sharing.groovnow.com/shared/linkid-connect" target="_blank">Learn more</a>`,
    },
    {
      Header: "Move",
      Body: `<strong>Move</strong> to release endorphins which give you an immediate mood boost. Even a little movement every day protects against anxiety and depression.
      <br><strong>Move</strong> is all about keeping physically active. It’s the things you do to move your body in a way that feels good.
      <br><a href="https://sharing.groovnow.com/shared/linkid-move" target="_blank">Learn more</a>`,
    },
    {
      Header: "Celebrate",
      Body: `<strong>Celebrate</strong> to release dopamine and rewire your brain for optimism. Having a more positive outlook on life helps you to deal with life’s challenges.
      <br><strong>Celebrate</strong> is all about taking time to celebrate and appreciate YOU. It’s the things you do to notice the positive, to be kind to yourself, and to feel good about yourself.
      <br><a href="https://sharing.groovnow.com/shared/linkid-celebrate" target="_blank">Learn more</a>`,
    },
    {
      Header: "Enjoy",
      Body: `<strong>Enjoy</strong> to get a good dose of serotonin.
      <br><strong>Enjoy</strong> is all about having things to look forward to. It’s the things you do for pleasure and fun, and to practise self-care.
      <br><a href="https://sharing.groovnow.com/shared/linkid-enjoy" target="_blank">Learn more</a>`,
    },
  ],
};
