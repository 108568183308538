import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SIX_PILLARS_SURVEY_REPORT } from "../constants/breakdown.constants";
import {
  SIX_PILLARS_WELLBEING_RESULT_HELPTEXT_ID,
  BREAKDOWN_TITLE_ID,
} from "../constants/test-ids/breakdown.ids";
import groovColors from "../styles/colors";
import Navigation from "./Navigation";
import SixPillarsAccordion from "./SixPillarsAccordion";
import SurveySelection from "./SurveySelection";
import { dateSelectionItemBuilder } from "../utils/selectionItemHelpers";
import ErrorCard from "./common/ErrorCard";
import LoadingCard from "./common/LoadingCard";
import NoDataCard from "./common/NoDataCard";
import { SelectionItem } from "../types/SelectionItem";
import Banner from "./Banner";
import { Sorting } from "../types/enums/Sorting";
import { WellbeingMetricDetails } from "../types/WellbeingMetricDetails";
import {
  SortSixPillarsReportItems,
  MapToWellbeingItems,
} from "../utils/reportItemHelpers";
import { useAppMediaQuery } from "../hooks/useAppMediaQuery";
import { useAppSelector } from "../hooks/useAppSelector";
import {
  selectEmailAddress,
  selectIsUserAuthenticated,
  selectOrgId,
  selectUserId,
} from "../store/selectors/authSelectors";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { getUserInfoAction } from "../store/actions/authActions";
import { useNavigate, useParams } from "react-router-dom";
import { getNavigationItems } from "../utils/navigationItemHelpers";
import { getReports } from "../store/services/sixPillarsReportService";
import { BREAKDOWN, LOGIN } from "../constants/routes";
import { ERROR_STATUS } from "../constants/error.constants";
import { trackEvent, trackUser } from "../analytics/AnalyticsPublisher";
import { AnalyticsEvent } from "../types/enums/AnalyticsEvent";
import theme from "../styles/theme";
import {
  BUTTON,
  COLLAPSE_ALL_REPORT_BREAKDOWN_ACCORDION_BUTTON,
  EXPAND_ALL_REPORT_BREAKDOWN_ACCORDION_BUTTON,
  FILTER,
  REPORT_BREAKDOWN,
  REPORT_BREAKDOWN_FILTER,
  REPORT_BREAKDOWN_PAGE,
  REPORT_BREAKDOWN_SORTER,
  SORTER,
} from "../constants/analytics.constants";
import TwoColumnContainer from "./layout/TwoColumnContainer";
import StyledHelpOutlineIcon from "./styled/StyledHelpOutlineIcon";
import { SIX_PILLARS_WELLBEING_RESULT_HELPTEXT } from "../constants/overview.constants";
import { Helptext } from "../types/Helptext";
import HelptextDrawer from "./helptext/HelptextDrawer";

const Breakdown = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [skip, setSkip] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [helptext, setHelptext] = useState<Helptext>();
  const emailAddress = useAppSelector(selectEmailAddress);
  const userId = useAppSelector(selectUserId);
  const orgId = useAppSelector(selectOrgId);
  const { responseId } = useParams();
  const breakdownNavigationItems = getNavigationItems(responseId);
  const isAuthenticated = useAppSelector(selectIsUserAuthenticated);

  if (responseId && isAuthenticated) navigate(BREAKDOWN);

  if (emailAddress === "" && responseId === undefined) {
    dispatch(getUserInfoAction());
  }

  const {
    data: reports,
    error,
    isLoading,
    isUninitialized,
  } = getReports(emailAddress, responseId, skip);

  const [selectedSurvey, setSelectedSurvey] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [sorting, setSorting] = useState<string>(Sorting.FrequencyAscending);
  const { columns } = useAppMediaQuery();

  const report = reports.find((d) => d.date.toString() === selectedSurvey);
  const reportItems = report?.reportItem ?? [];
  useEffect(() => {
    if (emailAddress !== "") {
      setSkip(false);
    }
  }, [emailAddress]);

  const sortedReportItems = SortSixPillarsReportItems(
    reportItems,
    sorting as Sorting
  );
  const wellbeingItems: WellbeingMetricDetails[] = MapToWellbeingItems(
    sortedReportItems,
    expanded
  );
  const surveys: SelectionItem[] = dateSelectionItemBuilder(
    reports
      .map((r) => r.date)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
  );

  if (surveys.length > 0 && selectedSurvey === "")
    setSelectedSurvey(surveys[0].key);

  const handleSurveyChange = (event: SelectChangeEvent) => {
    setSelectedSurvey(event.target.value as string);
    trackEvent(AnalyticsEvent.OptionSelectEvent, {
      elementType: FILTER,
      elementName: REPORT_BREAKDOWN_FILTER,
      value: event.target.value,
    });
  };

  const handleCollapseExpand = () => {
    setExpanded(!expanded);
    trackEvent(AnalyticsEvent.OnPressedEvent, {
      elementType: BUTTON,
      elementName: expanded
        ? COLLAPSE_ALL_REPORT_BREAKDOWN_ACCORDION_BUTTON
        : EXPAND_ALL_REPORT_BREAKDOWN_ACCORDION_BUTTON,
    });
  };

  const handleSorting = (event: SelectChangeEvent) => {
    setSorting(event.target.value);
    trackEvent(AnalyticsEvent.OptionSelectEvent, {
      elementType: SORTER,
      elementName: REPORT_BREAKDOWN_SORTER,
      value: event.target.value,
    });
  };

  useEffect(() => {
    if (report) {
      trackEvent(AnalyticsEvent.PageViewEvent, {
        name: REPORT_BREAKDOWN_PAGE,
      });
    }
  }, []);

  useEffect(() => {
    if (report) {
      if (userId) {
        trackUser(userId, orgId);
      }
      trackEvent(AnalyticsEvent.FeatureViewEvent, {
        name: REPORT_BREAKDOWN,
        responseId: report.responseId,
      });
    }
  }, [reports, selectedSurvey]);

  if (error) {
    if (ERROR_STATUS in error && error.status === 410) navigate(LOGIN);
    return <ErrorCard error={error} />;
  } else if (isLoading || isUninitialized) {
    return <LoadingCard />;
  } else if (!reports || reports.length === 0) {
    return <NoDataCard />;
  }

  return (
    <Grid container spacing={3} justifyContent={"center"} columns={columns}>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <Banner />
      </Grid>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <TwoColumnContainer
          firstColumn={
            <Typography
              variant="h1"
              color={groovColors.blue[130]}
              data-testid={BREAKDOWN_TITLE_ID}
              sx={{
                paddingTop: theme.spacing(1),
              }}
            >
              {SIX_PILLARS_SURVEY_REPORT}
            </Typography>
          }
          secondColumn={
            <StyledHelpOutlineIcon
              data-testid={SIX_PILLARS_WELLBEING_RESULT_HELPTEXT_ID}
              onClick={() => {
                setOpenDrawer(true);
                setHelptext(SIX_PILLARS_WELLBEING_RESULT_HELPTEXT as Helptext);
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <Navigation navigationItems={breakdownNavigationItems} />
      </Grid>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <SurveySelection
          surveyRecords={surveys}
          survey={selectedSurvey}
          handleSurveyChange={handleSurveyChange}
          handleCollapseExpand={handleCollapseExpand}
          expanded={expanded}
          sorting={sorting}
          handleSorting={handleSorting}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12} lg={8}>
        {wellbeingItems.map((item) => {
          return (
            <SixPillarsAccordion
              key={item.key}
              wellbeingItem={item}
              expanded={expanded}
            />
          );
        })}
      </Grid>
      <HelptextDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        helptext={helptext}
      />
    </Grid>
  );
};

export default Breakdown;
