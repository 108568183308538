import app_store from "../../assets/badges/app_store_badge.png";
import play_store from "../../assets/badges/play_store_badge.png";
import { Button, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import {
  GET_INTO_GROOV,
  APP_BLURB,
  APP_STORE,
  APP_STORE_LINK,
  PLAY_STORE_LINK,
} from "../../constants/components/footer.constants";
import {
  APP_FOOTER,
  APP_STORE_QUESTION,
  APP_STORE_BLURB,
  APP_STORE_INSTRUCTIONS,
  PLAY_STORE_BADGE,
  APP_STORE_BADGE,
} from "../../constants/test-ids/footer.ids";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";
import AppStoreBadge from "../AppStoreBadge";
import StyledBoxSpace from "../styled/StyledBoxSpace";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import { trackEvent } from "../../analytics/AnalyticsPublisher";
import { AnalyticsEvent } from "../../types/enums/AnalyticsEvent";
import {
  APP_STORE_ELEMENT,
  BUTTON,
  PLAY_STORE_ELEMENT,
} from "../../constants/analytics.constants";

const StyledPaper = styled(Paper)({
  backgroundColor: groovColors.neutral[100],
  color: groovColors.neutral[0],
  padding: `${theme.spacing(2.5)} ${theme.spacing(3.25)} ${theme.spacing(
    5.25
  )} ${theme.spacing(3.25)}`,
  margin: `${theme.spacing(5.5)} ${theme.spacing(0)}`,
});

const Footer = () => {
  const { columns, xsScreenAndSmScreen } = useAppMediaQuery();

  const handleAppStore = (elementName: string, link: string): void => {
    trackEvent(AnalyticsEvent.OnPressedEvent, {
      elementType: BUTTON,
      elementName: elementName,
    });
    window.open(link, "_blank");
  };

  return (
    <StyledPaper elevation={0}>
      <Grid container data-testid={APP_FOOTER} columns={columns} spacing={2}>
        <Grid item xs={4} sm={8} md={6} lg={8}>
          <Typography variant="h3" data-testid={APP_STORE_QUESTION}>
            {GET_INTO_GROOV}
          </Typography>
          <StyledBoxSpace space={1} />
          <Typography
            variant="body1"
            data-testid={APP_STORE_BLURB}
            color={groovColors.neutral[20]}
          >
            {APP_BLURB}
          </Typography>
          <Typography
            variant="body1"
            data-testid={APP_STORE_INSTRUCTIONS}
            color={groovColors.neutral[20]}
          >
            {APP_STORE}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={8}
          md={2}
          lg={4}
          textAlign={xsScreenAndSmScreen ? "inherit" : "right"}
        >
          <Button
            onClick={() => handleAppStore(APP_STORE_ELEMENT, APP_STORE_LINK)}
          >
            <AppStoreBadge
              image={app_store}
              alt="Apple App Store"
              data-testid={APP_STORE_BADGE}
            />
          </Button>
          <Button
            onClick={() => handleAppStore(PLAY_STORE_ELEMENT, PLAY_STORE_LINK)}
          >
            <AppStoreBadge
              image={play_store}
              alt="Google Play Store"
              data-testid={PLAY_STORE_BADGE}
            />
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default Footer;
