import React from "react";
import { PillarGradedScoreCard } from "../../types/PillarGradedScoreCard";
import { Grid } from "@mui/material";
import { PILLAR_BOXES_ID } from "../../constants/test-ids/pillarBoxes.ids";
import PillarMessage from "./PillarMessage";

interface Props {
  scoreCards: PillarGradedScoreCard[];
}

const PillarBoxes: React.FC<Props> = ({ scoreCards }) => {
  return (
    <Grid container spacing={2} data-testid={PILLAR_BOXES_ID}>
      {scoreCards
        .sort((a, b) => b.scoreSum - a.scoreSum)
        .map((s) => {
          return (
            <Grid item xs={12} key={s.pillar}>
              <PillarMessage scoreCard={s} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default PillarBoxes;
