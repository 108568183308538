import { Button, styled } from "@mui/material";
import theme from "../../styles/theme";
import groovColors from "../../styles/colors";

const StyledButton = styled(Button)({
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: `${groovColors.blue[5]} !important`,
  },
  borderRadius: theme.spacing(1.5),
});
export default StyledButton;
