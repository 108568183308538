import { createSlice } from "@reduxjs/toolkit";
import jwt_decode, { JwtPayload } from "jwt-decode";
import {
  ORG_ID,
  REFRESH_TOKEN,
  USER_ID,
  USER_TOKEN,
} from "../../constants/auth.constants";
import {
  logoutAction,
  loginAction,
  refreshLoginAction,
  getUserInfoAction,
  loginTokenExchangeAction,
} from "../actions/authActions";

const accessToken = localStorage.getItem(USER_TOKEN) ?? "";
const refreshToken = localStorage.getItem(REFRESH_TOKEN) ?? "";
const userId = localStorage.getItem(USER_ID) ?? "";
const orgId = localStorage.getItem(ORG_ID) ?? "";

interface AuthState {
  loading: boolean;
  accessToken: string;
  userId: string;
  orgId: string;
  expiry: number;
  refreshToken: string;
  emailAddress: string;
  error: unknown;
}

const initialState: AuthState = {
  loading: false,
  accessToken,
  userId,
  orgId,
  expiry: 0,
  refreshToken,
  emailAddress: "",
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.accessToken = payload.accessToken;
        const { org_id, sub } = parseAccessToken(payload.accessToken);
        state.userId = sub;
        state.orgId = org_id;
        state.expiry = payload.expiry;
        state.refreshToken = payload.refreshToken;
        saveToLocalStorage(
          payload.accessToken,
          payload.refreshToken,
          sub,
          org_id
        );
      })
      .addCase(loginAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(logoutAction, () => {
        localStorage.removeItem(USER_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(ORG_ID);
        return {
          ...initialState,
          accessToken: "",
          refreshToken: "",
          userId: "",
          orgId: "",
        };
      })
      .addCase(refreshLoginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshLoginAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.accessToken = payload.accessToken;
        const { org_id, sub } = parseAccessToken(payload.accessToken);
        state.userId = sub;
        state.orgId = org_id;
        state.expiry = payload.expiry;
        state.refreshToken = payload.refreshToken;
        saveToLocalStorage(
          payload.accessToken,
          payload.refreshToken,
          sub,
          org_id
        );
      })
      .addCase(refreshLoginAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getUserInfoAction.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
          emailAddress: "",
        };
      })
      .addCase(getUserInfoAction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          emailAddress: payload.emailAddress,
        };
      })
      .addCase(getUserInfoAction.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          error: payload,
        };
      })
      .addCase(loginTokenExchangeAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginTokenExchangeAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.accessToken = payload.accessToken;
        const { org_id, sub } = parseAccessToken(payload.accessToken);
        state.userId = sub;
        state.orgId = org_id;
        state.expiry = payload.expiry;
        state.refreshToken = payload.refreshToken;
        saveToLocalStorage(
          payload.accessToken,
          payload.refreshToken,
          sub,
          org_id
        );
      })
      .addCase(loginTokenExchangeAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

interface AccessToken extends JwtPayload {
  sub: string;
  org_id: string;
}

const parseAccessToken = (token: string): AccessToken => {
  return jwt_decode<AccessToken>(token);
};

function saveToLocalStorage(
  accessToken: string,
  refreshToken: string,
  userId: string,
  orgId: string
) {
  localStorage.setItem(USER_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
  localStorage.setItem(USER_ID, userId);
  localStorage.setItem(ORG_ID, orgId);
}

export const authReducer = authSlice.reducer;

export default authSlice;
