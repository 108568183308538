import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState } from "..";
import { ApiResponse } from "../../types/ApiResponse";
import {
  getUserInfo,
  login,
  loginTokenExchange,
  refreshLogin,
} from "../services/authService";

interface LoginRequest {
  email: string;
  password: string;
}

interface LoginResponse extends ApiResponse {
  accessToken: string;
  expiry: number;
  refreshToken: string;
}

interface UserInfoResponse extends ApiResponse {
  emailAddress: string;
}

export const loginAction = createAsyncThunk<
  LoginResponse,
  LoginRequest,
  {
    state: RootState;
  }
>("auth/login", async ({ email, password }, { rejectWithValue }) => {
  try {
    const data = await login(email, password);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue(error);
  }
});

export const loginTokenExchangeAction = createAsyncThunk<
  LoginResponse,
  string,
  {
    state: RootState;
  }
>("auth/login_token_exchange", async (token, { rejectWithValue }) => {
  try {
    const data = await loginTokenExchange(token);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue(error);
  }
});

export const getUserInfoAction = createAsyncThunk<
  UserInfoResponse,
  void,
  {
    state: RootState;
  }
>("auth/userinfo", async (_, { getState, rejectWithValue }) => {
  try {
    const token = getState().auth.accessToken;
    const data = await getUserInfo(token);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue(error);
  }
});

export const logoutAction = createAction<void, "auth/logout">("auth/logout");

export const refreshLoginAction = createAsyncThunk<
  LoginResponse,
  void,
  {
    state: RootState;
  }
>("auth/refresh", async (_, { getState, rejectWithValue }) => {
  try {
    const refreshToken = getState().auth.refreshToken;
    const data = await refreshLogin(refreshToken);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue(error);
  }
});
