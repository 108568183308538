import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  SIX_PILLARS_STYLED_ACCORDION_ID,
  SIX_PILLARS_SUMMARY,
  SIX_PILLARS_DETAILS,
} from "../constants/test-ids/sixPillarsAccordion.ids";
import groovColors from "../styles/colors";
import StyledBoxFill from "./styled/StyledBoxFill";
import StyledExpandMoreIcon from "./styled/StyledExpandMoreIcon";
import WellbeingItem from "./wellbeing/WellbeingItem";
import { trackEvent } from "../analytics/AnalyticsPublisher";
import { AnalyticsEvent } from "../types/enums/AnalyticsEvent";
import {
  ACCORDION,
  QUESTION_ACCORDION,
} from "../constants/analytics.constants";

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} expanded={props.expanded} {...props} />
))(({ theme }) => ({
  border: `1px solid ${groovColors.neutral[20]}`,
  borderRadius: theme.spacing(1.5),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2.5),
  "&:before": {
    display: "none",
  },
  "&:last-child": {
    borderBottomLeftRadius: theme.spacing(1.5),
    borderBottomRightRadius: theme.spacing(1.5),
  },
  "&:first-of-type": {
    borderTopLeftRadius: theme.spacing(1.5),
    borderTopRightRadius: theme.spacing(1.5),
  },
  "&.Mui-expanded": {
    margin: `${theme.spacing(3)} 0`,
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  padding: theme.spacing(0.5),
  ".MuiAccordionSummary-expandIconWrapper": {
    position: "absolute",
    top: 0,
    right: 0,
  },
  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  ".MuiAccordionSummary-content": {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

interface Props {
  wellbeingItem: {
    key: string;
    title: string;
    rating: number;
    pillar: string;
    details: string;
    expanded: boolean;
  };
  expanded: boolean;
}

const SixPillarsAccordion: React.FC<Props> = ({ wellbeingItem, expanded }) => {
  const [item, setItem] = useState(wellbeingItem);
  const [itemExpanded, setItemExpanded] = useState(expanded);

  useEffect(() => {
    item.expanded = expanded;
    setItem(item);
    setItemExpanded(expanded);
  }, [expanded]);

  const handleClick = () => {
    item.expanded = !itemExpanded;
    setItem(item);
    setItemExpanded(!itemExpanded);
    trackEvent(AnalyticsEvent.OnPressedEvent, {
      elementType: ACCORDION,
      elementName: QUESTION_ACCORDION,
      key: item.key,
      title: item.title,
      expanded: item.expanded,
    });
  };
  return (
    <StyledAccordion
      key={item.key}
      expanded={item.expanded}
      data-testid={SIX_PILLARS_STYLED_ACCORDION_ID}
    >
      <StyledAccordionSummary
        style={{
          padding: 0,
        }}
        expandIcon={<StyledExpandMoreIcon />}
        data-testid={SIX_PILLARS_SUMMARY}
        onClick={handleClick}
      >
        <StyledBoxFill>
          <WellbeingItem item={item} />
        </StyledBoxFill>
      </StyledAccordionSummary>
      <StyledAccordionDetails data-testid={SIX_PILLARS_DETAILS}>
        <Typography
          variant="body1"
          color={groovColors.neutral[80]}
          dangerouslySetInnerHTML={{
            __html: item.details,
          }}
        ></Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default SixPillarsAccordion;
