import React, { useEffect } from "react";
import LifeRingColored from "../../assets/svgComponents/LifeRingColored";
import {
  NZ_HOTLINE,
  AUSTRALIAN_HOTLINE,
  HELP_TEXT,
  NEW_ZEALAND,
  AUSTRALIA,
  HELP_LINE,
  NEED_TO_TALK,
} from "../../constants/components/lifeRingModal.constants";
import theme from "../../styles/theme";
import { Typography, Modal, Grid } from "@mui/material";
import HelpLineBox from "./HelpLineBox";
import StyledPaperCustom from "../styled/StyledPaperCustom";
import groovColors from "../../styles/colors";
import StyledBoxSpace from "../styled/StyledBoxSpace";
import {
  HELP_TEXT_ID,
  LIFE_RING_MODAL_ID,
  NEED_TO_TALK_ID,
} from "../../constants/test-ids/lifeRingModal.ids";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import { trackEvent } from "../../analytics/AnalyticsPublisher";
import { AnalyticsEvent } from "../../types/enums/AnalyticsEvent";
import StyledCloseIcon from "../styled/StyledCloseIcon";
import { LIFE_RING_MODAL } from "../../constants/analytics.constants";

interface Props {
  closeModal: () => void;
  open: boolean;
}

const LifeRingModal: React.FC<Props> = ({ open, closeModal }) => {
  const { columns, xsScreenAndSmScreen } = useAppMediaQuery();

  useEffect(() => {
    if (open) {
      trackEvent(AnalyticsEvent.ModalViewEvent, {
        name: LIFE_RING_MODAL,
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby={HELP_LINE}
      aria-describedby={HELP_LINE}
      sx={{ zIndex: 100000 }}
      data-testid={LIFE_RING_MODAL_ID}
    >
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          sx={{
            margin: `auto ${xsScreenAndSmScreen ? theme.spacing(2) : "auto"}`,
            marginTop: theme.spacing(8.5),
          }}
          display={"flex"}
          justifyContent={"center"}
        >
          <StyledPaperCustom
            elevation={0}
            color={groovColors.neutral[0]}
            padding={theme.spacing(2)}
            borderRadius={theme.spacing(2)}
            maxWidth={"736px"}
          >
            <Grid container columns={columns} justifyContent={"center"}>
              <Grid item xs={12}>
                <Grid container flexDirection={"row-reverse"}>
                  <StyledCloseIcon onClick={closeModal} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                sm={8}
                md={5}
                lg={7}
                sx={{
                  marginTop: theme.spacing(3.5),
                }}
                textAlign={"center"}
              >
                <LifeRingColored height={75} width={75} />
                <Typography
                  variant="h1"
                  color={groovColors.blue[130]}
                  sx={{ marginTop: theme.spacing(3) }}
                  data-testid={NEED_TO_TALK_ID}
                >
                  {NEED_TO_TALK}
                </Typography>
                <Typography
                  variant="body1"
                  color={groovColors.neutral[80]}
                  sx={{ marginTop: theme.spacing(2) }}
                  data-testid={HELP_TEXT_ID}
                >
                  {HELP_TEXT}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={8}
                md={5}
                lg={7}
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <HelpLineBox country={NEW_ZEALAND} helpLine={NZ_HOTLINE} />
                <HelpLineBox
                  country={AUSTRALIA}
                  helpLine={AUSTRALIAN_HOTLINE}
                />
              </Grid>
            </Grid>
            <StyledBoxSpace space={5.5} />
          </StyledPaperCustom>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LifeRingModal;
