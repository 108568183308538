import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  SIX_PILLARS_SURVEY,
  OVERVIEW_WELLBEING_HEADER,
  OVERVIEW_SIX_PILLARS_HEADER,
  SIX_PILLARS_ANSWERS,
  GENERAL_WELLBEING_ANSWERS,
  SIX_PILLARS_WELLBEING_HELPTEXT,
  SIX_PILLARS_WELLBEING_RESULT_HELPTEXT,
  SIX_PILLARS_GENERAL_WELLBEING_HELPTEXT,
} from "../constants/overview.constants";
import {
  OVERVIEW_SIX_PILLARS_HEADER_ID,
  OVERVIEW_TITLE_ID,
  SIX_PILLARS_WELLBEING_RESULT_HELPTEXT_ID,
  OVERVIEW_WELLBEING_HEADER_ID,
  SIX_PILLARS_GENERAL_WELLBEING_HELPTEXT_ID,
  SIX_PILLARS_WELLBEING_HELPTEXT_ID,
} from "../constants/test-ids/overview.ids";
import groovColors from "../styles/colors";
import { SelectionItem } from "../types/SelectionItem";
import { dateSelectionItemBuilder } from "../utils/selectionItemHelpers";
import ErrorCard from "./common/ErrorCard";
import LoadingCard from "./common/LoadingCard";
import NoDataCard from "./common/NoDataCard";
import Navigation from "./Navigation";
import SurveyComparison from "./SurveyComparison";
import { Pillar } from "../types/enums/Pillar";
import Banner from "./Banner";
import AnswerBlock from "./answers/AnswerBlock";
import PillarBoxes from "./pillboxes/PillarBoxes";
import {
  GetSixPillarsScoreCards,
  GradeScoreCards,
} from "../utils/reportItemHelpers";
import { useAppMediaQuery } from "../hooks/useAppMediaQuery";
import { useAppSelector } from "../hooks/useAppSelector";
import {
  selectEmailAddress,
  selectIsUserAuthenticated,
  selectOrgId,
  selectUserId,
} from "../store/selectors/authSelectors";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { getUserInfoAction } from "../store/actions/authActions";
import { useNavigate, useParams } from "react-router-dom";
import { getNavigationItems } from "../utils/navigationItemHelpers";
import { getReports } from "../store/services/sixPillarsReportService";
import { LOGIN, OVERVIEW } from "../constants/routes";
import { ERROR_STATUS } from "../constants/error.constants";
import { trackEvent, trackUser } from "../analytics/AnalyticsPublisher";
import { AnalyticsEvent } from "../types/enums/AnalyticsEvent";
import theme from "../styles/theme";
import TwoColumnContainer from "./layout/TwoColumnContainer";
import StyledHelpOutlineIcon from "./styled/StyledHelpOutlineIcon";
import { Helptext } from "../types/Helptext";
import {
  COMPARER,
  FILTER,
  REPORT_OVERVIEW,
  REPORT_OVERVIEW_COMPARER,
  REPORT_OVERVIEW_FILTER,
  REPORT_OVERVIEW_PAGE,
} from "../constants/analytics.constants";
import HelptextDrawer from "./helptext/HelptextDrawer";

const Overview = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [skip, setSkip] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [helptext, setHelptext] = useState<Helptext>();
  const emailAddress = useAppSelector(selectEmailAddress);
  const userId = useAppSelector(selectUserId);
  const orgId = useAppSelector(selectOrgId);
  const { responseId } = useParams();
  const isAuthenticated = useAppSelector(selectIsUserAuthenticated);

  if (responseId && isAuthenticated) navigate(OVERVIEW);

  // Move this to helper, call when event is triggered

  const overviewNavigationItems = getNavigationItems(responseId);

  if (emailAddress === "" && responseId === undefined) {
    dispatch(getUserInfoAction());
  }

  const {
    data: reports,
    error,
    isLoading,
    isUninitialized,
  } = getReports(emailAddress, responseId, skip);

  const [selection, setSelection] = useState("");
  const [compareSelection, setCompareSelection] = useState("");
  const { columns } = useAppMediaQuery();

  const surveys: SelectionItem[] = dateSelectionItemBuilder(
    reports
      .map((r) => r.date)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
  );

  const report = reports.find((r) => r.date.toString() === selection);
  const wellbeingMetrics =
    report?.reportItem.filter((p) => p.pillar === Pillar[Pillar.Wellbeing]) ??
    [];

  const sixPillarsMetrics =
    report?.reportItem.filter((p) => p.pillar !== Pillar[Pillar.Wellbeing]) ??
    [];

  const scoreCards = GetSixPillarsScoreCards(sixPillarsMetrics);
  const gradedScoreCards = GradeScoreCards(scoreCards);

  if (surveys.length > 0) {
    if (selection === "") setSelection(surveys[0].key);
    if (compareSelection === "") setCompareSelection(surveys[0].key);
  }

  const handleSelectionChange = (event: SelectChangeEvent) => {
    setSelection(event.target.value as string);
    trackEvent(AnalyticsEvent.OptionSelectEvent, {
      elementType: FILTER,
      elementName: REPORT_OVERVIEW_FILTER,
      value: event.target.value,
    });
  };

  const handleComparisonChange = (event: SelectChangeEvent) => {
    setCompareSelection(event.target.value as string);
    trackEvent(AnalyticsEvent.OptionSelectEvent, {
      elementType: COMPARER,
      elementName: REPORT_OVERVIEW_COMPARER,
      value: event.target.value,
    });
  };

  useEffect(() => {
    if (emailAddress !== "") {
      setSkip(false);
    }
  }, [emailAddress]);

  useEffect(() => {
    if (report) {
      trackEvent(AnalyticsEvent.PageViewEvent, {
        name: REPORT_OVERVIEW_PAGE,
      });
    }
  }, []);

  useEffect(() => {
    if (report) {
      if (userId) {
        trackUser(userId, orgId);
      }
      trackEvent(AnalyticsEvent.FeatureViewEvent, {
        name: REPORT_OVERVIEW,
        responseId: report.responseId,
      });
    }
  }, [reports, selection]);

  if (error) {
    if (ERROR_STATUS in error && error.status === 410) navigate(LOGIN);
    return <ErrorCard error={error} />;
  } else if (isLoading || isUninitialized) {
    return <LoadingCard />;
  } else if (!reports || reports.length === 0) {
    return <NoDataCard />;
  }

  return (
    <Grid container spacing={3} columns={columns}>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <Banner />
      </Grid>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <TwoColumnContainer
          firstColumn={
            <Typography
              variant="h1"
              color={groovColors.blue[130]}
              data-testid={OVERVIEW_TITLE_ID}
              sx={{
                paddingTop: theme.spacing(1),
              }}
            >
              {SIX_PILLARS_SURVEY}
            </Typography>
          }
          secondColumn={
            <StyledHelpOutlineIcon
              data-testid={SIX_PILLARS_WELLBEING_RESULT_HELPTEXT_ID}
              onClick={() => {
                setOpenDrawer(true);
                setHelptext(SIX_PILLARS_WELLBEING_RESULT_HELPTEXT as Helptext);
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <Navigation navigationItems={overviewNavigationItems} />
      </Grid>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <SurveyComparison
          surveyRecords={surveys}
          comparisonSurveyRecords={surveys}
          selection={selection}
          comparisonSelection={compareSelection}
          handleSelectionChange={handleSelectionChange}
          handleComparisonChange={handleComparisonChange}
          comparisonEnabled={false}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={8} lg={12}>
        <Grid container justifyContent={"center"} columns={columns} spacing={2}>
          <Grid item xs={4} sm={8} md={8} lg={8}>
            <TwoColumnContainer
              firstColumn={
                <Typography
                  variant="h2"
                  color={groovColors.blue[130]}
                  data-testid={OVERVIEW_WELLBEING_HEADER_ID}
                >
                  {OVERVIEW_WELLBEING_HEADER}
                </Typography>
              }
              secondColumn={
                <StyledHelpOutlineIcon
                  data-testid={SIX_PILLARS_WELLBEING_HELPTEXT_ID}
                  onClick={() => {
                    setOpenDrawer(true);
                    setHelptext(SIX_PILLARS_WELLBEING_HELPTEXT as Helptext);
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={4} sm={8} md={8} lg={8}>
            <AnswerBlock title={SIX_PILLARS_ANSWERS} data={sixPillarsMetrics} />
          </Grid>
          <Grid item xs={4} sm={8} md={8} lg={8}>
            <PillarBoxes scoreCards={gradedScoreCards} />
          </Grid>
          <Grid item xs={4} sm={8} md={8} lg={8}>
            <TwoColumnContainer
              firstColumn={
                <Typography
                  variant="h2"
                  color={groovColors.blue[130]}
                  data-testid={OVERVIEW_SIX_PILLARS_HEADER_ID}
                >
                  {OVERVIEW_SIX_PILLARS_HEADER}
                </Typography>
              }
              secondColumn={
                <StyledHelpOutlineIcon
                  data-testid={SIX_PILLARS_GENERAL_WELLBEING_HELPTEXT_ID}
                  onClick={() => {
                    setOpenDrawer(true);
                    setHelptext(
                      SIX_PILLARS_GENERAL_WELLBEING_HELPTEXT as Helptext
                    );
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={4} sm={8} md={8} lg={8}>
            <AnswerBlock
              title={GENERAL_WELLBEING_ANSWERS}
              data={wellbeingMetrics}
            />
          </Grid>
        </Grid>
      </Grid>
      <HelptextDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        helptext={helptext}
      />
    </Grid>
  );
};

export default Overview;
