import { Grid } from "@mui/material";
import React from "react";

interface Props {
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode;
}

const TwoColumnContainer: React.FC<Props> = ({
  firstColumn: firstColumn,
  secondColumn: secondColumn,
}) => {
  return (
    <Grid container>
      <Grid item xs={11}>
        {firstColumn}
      </Grid>
      <Grid item xs display={"flex"} alignItems={"center"}>
        <Grid container direction={"row-reverse"}>
          {secondColumn}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TwoColumnContainer;
