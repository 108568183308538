import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import groovColors from "../styles/colors";
import { Typography } from "@mui/material";
import { NavigationItem } from "../types/NavigationItem";
import {
  NAVIGATION_BUTTON,
  NAVIGATION_ID,
} from "../constants/test-ids/navigation.ids";
import theme from "../styles/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ROOT } from "../constants/routes";

interface Props {
  navigationItems: NavigationItem[];
}

const StyledPaper = styled(Paper)({
  backgroundColor: groovColors.blue[1],
  width: "fit-content",
  borderRadius: theme.spacing(1.5),
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    color: groovColors.neutral[80],
    "&.Mui-disabled": {
      border: 0,
      color: groovColors.neutral[60],
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1),
    },
    "&.Mui-selected": {
      backgroundColor: groovColors.neutral[0],
      color: groovColors.blue[130],
      borderRadius: theme.spacing(1.5),
    },
    "&:hover": {
      backgroundColor: `${groovColors.blue[5]} !important`,
    },
    "&.Mui-selected:hover": {
      backgroundColor: `${groovColors.blue[5]} !important`,
    },
  },
}));

const Navigation: React.FC<Props> = ({ navigationItems }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(
    location.pathname == ROOT ? navigationItems[0].url : location.pathname
  );

  const handleTabClick = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    //todo fix double clicking removes highlight
    setTab(newAlignment);
  };

  const handleClick = (_event: React.MouseEvent<HTMLElement>, url: string) => {
    navigate(url);
  };

  return (
    <StyledPaper elevation={0} data-testid={NAVIGATION_ID}>
      <StyledToggleButtonGroup value={tab} exclusive onChange={handleTabClick}>
        {navigationItems.map((n) => {
          return (
            <ToggleButton
              value={n.url}
              key={n.name}
              data-testid={NAVIGATION_BUTTON}
              onClick={handleClick}
              style={{
                padding: theme.spacing(2),
                borderRadius: theme.spacing(1.5),
                border: `1px solid ${groovColors.blue[5]}`,
              }}
            >
              <Typography variant="button">{n.name}</Typography>
            </ToggleButton>
          );
        })}
      </StyledToggleButtonGroup>
    </StyledPaper>
  );
};

export default Navigation;
