import { Grid, ListItem, Typography } from "@mui/material";
import React from "react";
import {
  WELLBEING_TITLE,
  WELLBEING_SLIDER,
} from "../../constants/test-ids/wellbeing.ids";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import groovColors from "../../styles/colors";
import { WellbeingMetric } from "../../types/WellbeingMetric";
import PillarSticker from "../PillarSticker";
import WellbeingSlider from "./WellbeingSlider";
import theme from "../../styles/theme";

interface Props {
  item: WellbeingMetric;
}

const WellbeingItem: React.FC<Props> = ({ item }) => {
  const { columns, xsScreen } = useAppMediaQuery();
  return (
    <>
      <ListItem
        style={{
          padding: 0,
        }}
      >
        <Grid container columns={columns}>
          <Grid item xs={3} sm={6} md={6} lg={8}>
            <Typography
              data-testid={WELLBEING_TITLE}
              variant="h3"
              color={groovColors.neutral[95]}
            >
              {item.title}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={1.5} md={1.5} lg={3}>
            <Grid container flexDirection={xsScreen ? "row" : "row-reverse"}>
              <PillarSticker pillar={item.pillar} />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem
        style={{
          padding: `${theme.spacing(2)} 0`,
        }}
      >
        <WellbeingSlider data-testid={WELLBEING_SLIDER} rating={item.rating} />
      </ListItem>
    </>
  );
};

export default WellbeingItem;
