import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GroovIconLogo from "../../assets/svgComponents/GroovIconLogo";
import GroovLogo from "../../assets/svgComponents/GroovLogo";
import LifeRingColored from "../../assets/svgComponents/LifeRingColored";
import { LOGIN } from "../../constants/routes";
import {
  APP_HEADER,
  ORGANISATION_NAME,
} from "../../constants/test-ids/header.ids";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import { useAppSelector } from "../../hooks/useAppSelector";
import { logoutAction } from "../../store/actions/authActions";
import {
  selectIsUserAuthenticated,
  selectOrgId,
} from "../../store/selectors/authSelectors";
import { useGetOrganisationByIdQuery } from "../../store/services/contentService";
import groovColors from "../../styles/colors";
import LifeRingModal from "../lifeRing/LifeRingModal";
import { QuickNav } from "@groov/ui";
import { TOKEN_EXCHANGE_ENCRYPTION_KEY } from "../../config/app.config";
import StyledBoxCustom from "../styled/StyledBoxCustom";
import theme from "../../styles/theme";
import { QUICK_NAV_ID } from "../../constants/test-ids/quickNav.ids";
import { trackEvent } from "../../analytics/AnalyticsPublisher";
import { AnalyticsEvent } from "../../types/enums/AnalyticsEvent";
import {
  BUTTON,
  CLOSE_LIFE_RING,
  OPEN_LIFE_RING,
  SIGN_OUT,
} from "../../constants/analytics.constants";
import "../../styles/quickNav.css";

interface QuickNavComponentProps {
  accessToken?: string;
  onSignInClick?: () => void;
  onSignOutClick?: () => void;
  orgId?: string;
}

const QuickNavComponent = (props: QuickNavComponentProps) => {
  return props.accessToken ? (
    <StyledBoxCustom
      m={`0 0 0 ${theme.spacing(5.25)}`}
      p={`${theme.spacing(0.5)} 0 0 0`}
      data-testid={QUICK_NAV_ID}
    >
      <QuickNav
        accessToken={props.accessToken}
        organisationId={props.orgId}
        onSignInClick={props.onSignInClick}
        onSignOutClick={props.onSignOutClick}
        encryptionKey={TOKEN_EXCHANGE_ENCRYPTION_KEY}
      />
    </StyledBoxCustom>
  ) : null;
};

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orgId = useAppSelector(selectOrgId);
  const accessToken = useAppSelector(selectIsUserAuthenticated);
  const { data: organisation } = useGetOrganisationByIdQuery(orgId, {
    skip: accessToken == "" ? true : false,
  });
  const [lifeRingOpen, setLifeRingOpen] = useState(false);
  const { xsScreen } = useAppMediaQuery();

  const handleClick = () => {
    if (accessToken) {
      dispatch(logoutAction());
      trackEvent(AnalyticsEvent.OnPressedEvent, {
        elementType: BUTTON,
        elementName: SIGN_OUT,
      });
    }
    navigate(LOGIN);
  };

  const openLifeRing = () => {
    setLifeRingOpen(true);
    trackEvent(AnalyticsEvent.OnPressedEvent, {
      elementType: BUTTON,
      elementName: OPEN_LIFE_RING,
    });
  };

  const closeLifeRing = () => {
    setLifeRingOpen(false);
    trackEvent(AnalyticsEvent.OnPressedEvent, {
      elementType: BUTTON,
      elementName: CLOSE_LIFE_RING,
    });
  };

  return (
    <Grid container spacing={2} data-testid={APP_HEADER}>
      <Grid item xs={6}>
        {xsScreen ? (
          <GroovIconLogo />
        ) : (
          <>
            <GroovLogo />
            <Typography
              variant="button"
              color={groovColors.neutral[40]}
              display={"block"}
              data-testid={ORGANISATION_NAME}
            >
              {organisation?.name}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid container direction={"row-reverse"}>
          <QuickNavComponent
            accessToken={accessToken}
            orgId={orgId}
            onSignInClick={handleClick}
            onSignOutClick={handleClick}
          />
          &nbsp;
          <StyledBoxCustom onClick={openLifeRing}>
            <LifeRingColored height={24} width={24} />
          </StyledBoxCustom>
          <LifeRingModal open={lifeRingOpen} closeModal={closeLifeRing} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
