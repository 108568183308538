import React from "react";
import { useRouteError } from "react-router-dom";
import ErrorCard from "./components/common/ErrorCard";
import Layout from "./components/layout/Layout";
import { ErrorResponse } from "./types/ErrorResponse";

const ErrorPage = () => {
  const error = useRouteError() as ErrorResponse;

  return (
    <Layout>
      <ErrorCard error={error} />
    </Layout>
  );
};

export default ErrorPage;
