import { SelectionItem } from "../types/SelectionItem";
import { formatUtcDateToUsDateString } from "./dateHelpers";

export const dateSelectionItemBuilder = (list: Date[]): SelectionItem[] => {
  if (!list || list.length === 0) return [];

  return list.map((item) => {
    return {
      key: item.toString(),
      value: formatUtcDateToUsDateString(item),
    };
  });
};
