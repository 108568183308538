import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";

const StyledPaperBanner = styled(Paper)({
  borderRadius: theme.spacing(3),
  backgroundColor: groovColors.blue[5],
  padding: `${theme.spacing(7.5)} 0}`,
});

export default StyledPaperBanner;
