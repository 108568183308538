import { Sorting } from "../../types/enums/Sorting";

export const SURVEY_LABEL = "Date";
export const SORTING_LABEL = "Sort by";
export const EXPAND_ALL = "Expand all";
export const COLLAPSE_ALL = "Collapse all";
export const WELLBEING_ITEM_SORTING = {
  FREQUENCY_ASC: {
    KEY: Sorting.FrequencyAscending,
    VALUE: "Frequency (Low to High)",
  },
  FREQUENCY_DESC: {
    KEY: Sorting.FrequencyDescending,
    VALUE: "Frequency (High to Low)",
  },
  PILLARS: {
    KEY: Sorting.Pillars,
    VALUE: "Pillars",
  },
};
