import { Grid, Typography } from "@mui/material";
import React from "react";
import groovColors from "../../styles/colors";
import StyledPaperCustom from "../styled/StyledPaperCustom";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import theme from "../../styles/theme";
import {
  ANSWER_LEGEND_ID,
  ANSWER_ROW_ID,
  ANSWER_SCORE_ID,
  ANSWER_TEXT_ID,
  ANSWER_TOTAL_ID,
} from "../../constants/test-ids/answerRow.ids";

interface Props {
  answer: string;
  score: string;
  total: string;
  color: string;
}

const AnswerRow: React.FC<Props> = ({ answer, score, total, color }) => {
  return (
    <StyledPaperCustom
      elevation={0}
      padding={theme.spacing(2.5)}
      borderRadius={theme.spacing(1.5)}
      color={groovColors.neutral[0]}
      width={"100%"}
      data-testid={ANSWER_ROW_ID}
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item>
            <SquareRoundedIcon
              style={{ color: color, marginRight: theme.spacing(1) }}
              data-testid={ANSWER_LEGEND_ID}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="button"
              color={groovColors.neutral[80]}
              data-testid={ANSWER_TEXT_ID}
            >
              {answer}
            </Typography>
          </Grid>
          <Grid item xs>
            <Grid container direction={"row-reverse"}>
              <Typography
                variant="body1"
                color={groovColors.neutral[40]}
                data-testid={ANSWER_TOTAL_ID}
              >
                &nbsp;{`/ ${total}`}
              </Typography>
              <Typography
                variant="button"
                color={groovColors.neutral[80]}
                data-testid={ANSWER_SCORE_ID}
              >
                {`${score}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaperCustom>
  );
};

export default AnswerRow;
