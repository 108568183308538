import { Slider, styled } from "@mui/material";
import React from "react";
import {
  NONE_OF_THE_TIME,
  SOMETIMES,
  EVERYDAY,
} from "../../constants/components/wellbeingSlider.constants";
import { WELLBEING_SLIDER } from "../../constants/test-ids/wellbeing.ids";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";

const WELLBEING_SLIDER_MARKS = [
  {
    value: 0,
    label: "",
  },
  {
    value: 1,
    label: NONE_OF_THE_TIME,
  },
  {
    value: 2,
    label: "",
  },
  {
    value: 3,
    label: SOMETIMES,
  },
  {
    value: 4,
    label: "",
  },
  {
    value: 5,
    label: EVERYDAY,
  },
  {
    value: 6,
    label: "",
  },
];

const WELLBEING_SLIDER_MIN = 0;
const WELLBEING_SLIDER_MAX = WELLBEING_SLIDER_MARKS.length - 1;

const StyledSlider = styled(Slider)((props: { fullWidth: boolean }) => ({
  color: "transparent",
  height: 24,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    background: `linear-gradient(90deg, ${groovColors.mint[10]} 0% 33%, ${groovColors.mint[80]} 33% 66%, ${groovColors.mint[100]} 66%) fixed;`,
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: groovColors.mint[130],
    border: `4px solid ${groovColors.neutral[0]}`,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-mark": {
    backgroundColor: groovColors.neutral[40],
    marginTop: theme.spacing(2),
    height: 8,
    width: 1,
    '&[data-index="0"], &[data-index="2"], &[data-index="4"], &[data-index="6"]':
      {
        opacity: 0,
      },
  },
  "& .MuiSlider-markLabel": {
    ...theme.typography.h5,
    color: groovColors.neutral[40],
    marginTop: theme.spacing(2.5),
  },
  width: props.fullWidth ? "100%" : "55%",
}));

interface Props {
  rating: number;
}

const WellbeingSlider: React.FC<Props> = ({ rating }) => {
  const { xsScreen } = useAppMediaQuery();
  return (
    <StyledSlider
      data-testid={WELLBEING_SLIDER}
      value={rating}
      step={1}
      marks={WELLBEING_SLIDER_MARKS}
      min={WELLBEING_SLIDER_MIN}
      max={WELLBEING_SLIDER_MAX}
      fullWidth={xsScreen}
    />
  );
};

export default WellbeingSlider;
