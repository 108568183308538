import { Grid, Typography } from "@mui/material";
import React from "react";
import { ANSWER_BLOCK_TITLE_ID } from "../../constants/test-ids/answerBlock.ids";
import groovColors from "../../styles/colors";
import { Answer } from "../../types/enums/Answer";
import { AnswerSummary } from "../../types/AnswerSummary";
import { SixPillarsReportItem } from "../../types/SixPillarsReportItem";
import { GroupByRating } from "../../utils/reportItemHelpers";
import StyledWhitePaper from "../styled/StyledPaperWhite";
import AnswerChart from "./AnswerChart";
import AnswerHeader from "./AnswerHeader";
import AnswerRow from "./AnswerRow";

export interface Props {
  title: string;
  data: SixPillarsReportItem[];
}

const AnswerBlock: React.FC<Props> = ({ title, data: sixPillarsMetrics }) => {
  const countByRating = GroupByRating(sixPillarsMetrics);
  const answers: AnswerSummary[] = [
    {
      answer: `${Answer.Often}, ${Answer.Everyday}`,
      score: countByRating
        .filter((r) => r.rating > 3)
        .reduce((sum, rate) => sum + rate.score, 0),
      total: sixPillarsMetrics.length,
      color: groovColors.blue[30],
    },
    {
      answer: Answer.SomeOfTheTime,
      score: countByRating
        .filter((r) => r.rating === 3)
        .reduce((sum, rate) => sum + rate.score, 0),
      total: sixPillarsMetrics.length,
      color: groovColors.purple[20],
    },
    {
      answer: `${Answer.Rarely}, ${Answer.NoneOfTheTime}`,
      score: countByRating
        .filter((r) => r.rating < 3)
        .reduce((sum, rate) => sum + rate.score, 0),
      total: sixPillarsMetrics.length,
      color: groovColors.neutral[30],
    },
  ];
  return (
    <StyledWhitePaper elevation={0} variant="outlined">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            color={groovColors.neutral[95]}
            data-testid={ANSWER_BLOCK_TITLE_ID}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AnswerChart data={answers} />
        </Grid>
        <AnswerHeader />
        {answers.map((d) => {
          return (
            <AnswerRow
              key={d.answer}
              answer={d.answer}
              score={d.score.toString()}
              total={d.total.toString()}
              color={d.color}
            />
          );
        })}
      </Grid>
    </StyledWhitePaper>
  );
};

export default AnswerBlock;
