export const SIX_PILLARS_SURVEY = "Your 6 Pillars of Wellbeing Quiz Results";
export const OVERVIEW_WELLBEING_HEADER = "6 Pillars of Wellbeing";
export const OVERVIEW_WELLBEING_SUBHEADER = "Over the past 7 days...";
export const OVERVIEW_SIX_PILLARS_HEADER = "General Wellbeing";
export const OVERVIEW_SIX_PILLARS_SUBHEADER =
  "Over the past 7 days, you have done these...";

export const SIX_PILLARS_ANSWERS = "6 Pillar Actions by Frequency";
export const GENERAL_WELLBEING_ANSWERS = "General Wellbeing by Frequency";

export const SIX_PILLARS_WELLBEING_HELPTEXT = {
  Title: "6 Pillars of Wellbeing",
  Content: [
    {
      Header: "6 Pillars of Wellbeing Answers",
      Body: "This section summarises your response to the first 18 questions from the quiz, which relate to the 6 Pillars of Wellbeing.",
    },
    {
      Header: "Chart",
      Body: "This chart groups your answers by frequency, so you can see at a glance how frequently you hit all 18 pillar actions over the past 7 days. For simplicity, Often and Everyday are combined to indicate high frequency, and Rarely and None of the time to indicate low frequency.",
    },
    {
      Header: "Pillars",
      Body: "This section groups your answers by pillar, so you can understand which pillars you hit with high, medium and/or low frequency.",
    },
  ],
};

export const SIX_PILLARS_WELLBEING_RESULT_HELPTEXT = {
  Title: "Your 6 Pillars of Wellbeing Quiz Results",
  Content: [
    {
      Header: "How to understand your report",
      Body: `The 6 Pillars of Wellbeing Quiz asked you to think about the past 7 days and answer 21 questions in total.
      <br><br>
      The first 18 questions relate to the 6 Pillars - <i>Chill, Do, Move, Connect, Celebrate, Enjoy</i> - and there were 3 questions per pillar to find out how frequently you hit all 3 elements of the pillar.
      <br><br>
      The last 3 questions relate to your general wellbeing - how well you handled stress, your ability to get over setbacks and how often you felt cheerful. Research shows that small daily actions from the 6 Pillars can have a big impact on these 3 key dimensions of general wellbeing. At Groov we call this feeling good and functioning well!`,
    },
    {
      Header: "Overview Page",
      Body: "The Overview page shows a brief summary of your responses to the 6 Pillar and General Wellbeing sections. It will show you which pillars you hit with high frequency, and which pillars could do with some focus to get those feel-good chemicals buzzing!",
    },
    {
      Header: "Details Page",
      Body: `The Details page shows your response to each of the 21 questions, and goes into more detail about why each element of the 6 Pillars is important, offering simple ideas for how to fit them into your daily life.
      <br><br>
      You can sort the questions by frequency - low to high, high to low - or by pillar. When you sort by pillar, the 3 general wellbeing questions will display at the end. You can also collapse/expand to show or hide the detail for each question.`,
    },
  ],
};

export const SIX_PILLARS_GENERAL_WELLBEING_HELPTEXT = {
  Title: "General Wellbeing",
  Content: [
    {
      Header: "General Wellbeing",
      Body: `This chart displays your answers to the 3 general wellbeing questions by frequency. For simplicity, <i>Often</i> and <i>Everyday</i> are combined to indicate high frequency, and <i>Rarely</i> and <i>None of the time</i> to indicate low frequency.
      <br><br>
      General wellbeing questions:
      <br><br>
      &nbsp;&nbsp;1. I’ve handled stress well
      <br>
      &nbsp;&nbsp;2. I’ve been able to get over setbacks quickly
      <br>
      &nbsp;&nbsp;3. I’ve felt cheerful`,
    },
  ],
};
