export const BANNER_THANK_YOU = "Thanks for taking our 6 Pillars Quiz";
export const BANNER_TITLE = "Brain hacks to get your Groov on";

export const BANNER_BODY_1_PREFIX = "Did you know?";

export const BANNER_BODY_1 =
  "Building small actions from Groov’s 6 Pillars of Wellbeing into your daily life can rewire your brain!";

export const BANNER_BODY_2 =
  "Read on to see how often you’re already doing small actions from the 6 Pillars and get ideas for adding more wellbeing actions into your day.";

export const BANNER_BODY_3 =
  "We recommend choosing one small action from this report and doing it every day for a week. By starting with one thing, you’ll be more likely to stick with it and stay in your Groov.";
