import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";

const StyledWhitePaper = styled(Paper)({
  backgroundColor: groovColors.neutral[0],
  borderColor: groovColors.neutral[20],
  padding: theme.spacing(2.5),
  borderRadius: theme.spacing(2),
});

export default StyledWhitePaper;
