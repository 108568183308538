import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { contentApi } from "./services/contentService";
import { sixPillarsReportApi } from "./services/sixPillarsReportService";
import { authReducer } from "./slices/authSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  [sixPillarsReportApi.reducerPath]: sixPillarsReportApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(sixPillarsReportApi.middleware)
      .concat(contentApi.middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
