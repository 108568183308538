import Box from "@mui/material/Box";
import React, { useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import {
  ANSWERS_DATA_KEY,
  ANSWERS_DATA_VALUE,
} from "../../constants/components/answers.constants";
import { ANSWER_CHART_ID } from "../../constants/test-ids/answerChart.ids";
import { AnswerSummary } from "../../types/AnswerSummary";
import AnswerTooltip from "./AnswerTooltip";

export interface Props {
  data: AnswerSummary[];
}

const AnswerChart: React.FC<Props> = ({ data }) => {
  const [index, setIndex] = useState<number>(0);

  return (
    <Box
      data-testid={ANSWER_CHART_ID}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <PieChart
        width={264}
        height={264}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <Pie
          data={data}
          dataKey={ANSWERS_DATA_KEY}
          nameKey={ANSWERS_DATA_VALUE}
          innerRadius={80}
          isAnimationActive={false}
          startAngle={-270}
          endAngle={360}
        >
          {data.map((entry, index) => (
            <Cell
              key={index}
              style={{
                outline: "none",
              }}
              fill={data[index].color}
              onMouseEnter={() => setIndex(index)}
            />
          ))}
        </Pie>
        <Tooltip
          active
          content={<AnswerTooltip answerSummary={data[index]} />}
        />
      </PieChart>
    </Box>
  );
};

export default AnswerChart;
