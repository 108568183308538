import React from "react";
import theme from "../styles/theme";

interface Props {
  image: string;
  alt: string;
  "data-testid": string;
}

const AppStoreBadge: React.FC<Props> = ({
  image,
  alt,
  "data-testid": testId,
}) => {
  return (
    <img
      src={image}
      alt={alt}
      width={139}
      height={41}
      style={{
        marginRight: theme.spacing(2),
      }}
      data-testid={testId}
    />
  );
};

export default AppStoreBadge;
