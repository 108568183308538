import { Grid, Typography } from "@mui/material";
import React from "react";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";
import { AnswerSummary } from "../../types/AnswerSummary";
import StyledPaperCustom from "../styled/StyledPaperCustom";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import {
  ANSWER_TOOLTIP_ANSWER_ID,
  ANSWER_TOOLTIP_ANSWER_PERCENTAGE_ID,
  ANSWER_TOOLTIP_ICON_ID,
  ANSWER_TOOLTIP_ID,
} from "../../constants/test-ids/answerTooltip.ids";
import { getStringPercentageScore } from "../../utils/numberHelpers";

export interface Props {
  answerSummary: AnswerSummary;
}

const AnswerTooltip: React.FC<Props> = ({ answerSummary }) => {
  return (
    <StyledPaperCustom
      elevation={0}
      padding={theme.spacing(2.5)}
      borderRadius={theme.spacing(1.5)}
      color={groovColors.neutral[100]}
      width={"320px"}
      opacity={"80%"}
      data-testid={ANSWER_TOOLTIP_ID}
    >
      <Grid container>
        <Grid item xs={"auto"}>
          <SquareRoundedIcon
            style={{
              color: answerSummary.color,
              marginRight: theme.spacing(1),
            }}
            data-testid={ANSWER_TOOLTIP_ICON_ID}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body1"
            color={groovColors.neutral[0]}
            data-testid={ANSWER_TOOLTIP_ANSWER_ID}
          >
            {answerSummary.answer}
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container direction={"row-reverse"}>
            <Typography
              variant="button"
              color={groovColors.neutral[0]}
              data-testid={ANSWER_TOOLTIP_ANSWER_PERCENTAGE_ID}
            >
              {getStringPercentageScore(
                answerSummary.score,
                answerSummary.total
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaperCustom>
  );
};

export default AnswerTooltip;
