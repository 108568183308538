import { Grid, SelectChangeEvent } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import IconAscending from "../assets/svgComponents/IconAscending";
import IconDescending from "../assets/svgComponents/IconDescending";
import IconPillars from "../assets/svgComponents/IconPillars";
import {
  COLLAPSE_ALL,
  EXPAND_ALL,
  WELLBEING_ITEM_SORTING,
  SORTING_LABEL,
  SURVEY_LABEL,
} from "../constants/components/surveySelection.constants";
import {
  SURVEY_SELECTION_BUTTON_ID,
  SURVEY_SELECTION_EXPAND_COLLAPSE_ID,
  SURVEY_SELECTION_ID,
} from "../constants/test-ids/surveySelection.ids";
import { useAppMediaQuery } from "../hooks/useAppMediaQuery";
import groovColors from "../styles/colors";
import { SelectionItem } from "../types/SelectionItem";
import SelectionForm from "./SelectionForm";
import StyledButton from "./styled/StyledButton";
import { StyledPaperSelection } from "./styled/StyledPaperSelection";

interface Props {
  surveyRecords: SelectionItem[];
  survey: string;
  handleSurveyChange: (event: SelectChangeEvent) => void;
  handleCollapseExpand: () => void;
  expanded: boolean;
  sorting: string;
  handleSorting: (event: SelectChangeEvent) => void;
}

const sortingOptions = [
  {
    key: WELLBEING_ITEM_SORTING.FREQUENCY_ASC.KEY,
    value: WELLBEING_ITEM_SORTING.FREQUENCY_ASC.VALUE,
    icon: <IconAscending />,
  },
  {
    key: WELLBEING_ITEM_SORTING.FREQUENCY_DESC.KEY,
    value: WELLBEING_ITEM_SORTING.FREQUENCY_DESC.VALUE,
    icon: <IconDescending />,
  },
  {
    key: WELLBEING_ITEM_SORTING.PILLARS.KEY,
    value: WELLBEING_ITEM_SORTING.PILLARS.VALUE,
    icon: <IconPillars />,
  },
];

const SurveySelection: React.FC<Props> = ({
  surveyRecords,
  survey,
  handleSurveyChange,
  handleCollapseExpand,
  expanded,
  sorting,
  handleSorting,
}) => {
  const { columns, xsScreen } = useAppMediaQuery();
  return (
    <StyledPaperSelection
      elevation={0}
      variant={"outlined"}
      data-testid={SURVEY_SELECTION_ID}
    >
      <Grid container columns={columns} spacing={1}>
        <Grid item xs={4} sm={"auto"}>
          <SelectionForm
            items={surveyRecords}
            label={SURVEY_LABEL}
            selection={survey}
            handleChange={handleSurveyChange}
            fullWidth={xsScreen}
          />
        </Grid>
        <Grid item xs={4} sm>
          <SelectionForm
            items={sortingOptions}
            label={SORTING_LABEL}
            selection={sorting}
            handleChange={handleSorting}
            fullWidth={xsScreen}
          />
        </Grid>
        <Grid item xs={4} sm={"auto"}>
          <Grid container direction={"column-reverse"}>
            <StyledButton
              variant="text"
              onClick={handleCollapseExpand}
              data-testid={SURVEY_SELECTION_EXPAND_COLLAPSE_ID}
              sx={{
                border: `1px solid ${groovColors.blue[5]}`,
              }}
            >
              <Typography
                variant="button"
                color={groovColors.blue[130]}
                data-testid={SURVEY_SELECTION_BUTTON_ID}
              >
                {expanded ? COLLAPSE_ALL : EXPAND_ALL}
              </Typography>
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaperSelection>
  );
};

export default SurveySelection;
