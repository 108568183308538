import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";

const StyledPaperNotes = styled(Paper)({
  backgroundColor: groovColors.neutral[10],
  borderBlockColor: groovColors.neutral[20],
  padding: theme.spacing(2.5),
});

export default StyledPaperNotes;
