import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { CONTENT_API } from "../../config/api.config";
import { addAuthorizationHeader } from "../../utils/apiHelpers";
import { refreshLoginAndRetryQuery } from "./commonService";

const baseQuery = fetchBaseQuery({
  baseUrl: CONTENT_API.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.accessToken;
    addAuthorizationHeader(headers, accessToken);
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    result = await refreshLoginAndRetryQuery(
      result,
      api,
      args,
      extraOptions,
      baseQuery
    );
  }

  return result;
};

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getOrganisationById: builder.query<
      {
        name: string;
      },
      string
    >({
      query: (orgId) => `${CONTENT_API.ENDPOINTS.ORGANISATIONS}/${orgId}`,
    }),
  }),
});

export const { useGetOrganisationByIdQuery } = contentApi;
