import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GroovLogo from "../assets/svgComponents/GroovLogo";
import { OVERVIEW } from "../constants/routes";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { getUserInfoAction, loginAction } from "../store/actions/authActions";
import { selectIsUserAuthenticated } from "../store/selectors/authSelectors";
import adminImage from "../assets/images/AdminImage.png";
import StyledBoxSpace from "./styled/StyledBoxSpace";
import Typography from "@mui/material/Typography";
import { SIGN_IN } from "../constants/login.constants";
import StyledGridCenterAlign from "./styled/StyledGridCenterAlign";
import { LOGIN_ADMIN_IMAGE } from "../constants/test-ids/login.ids";
import LoginForm, { FormValues } from "./LoginForm";
import { AnalyticsEvent } from "../types/enums/AnalyticsEvent";
import { trackEvent } from "../analytics/AnalyticsPublisher";
import { LOGIN_PAGE } from "../constants/analytics.constants";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUserAuthenticated = useAppSelector(selectIsUserAuthenticated);

  const submitForm = async (formValues: FormValues) => {
    await dispatch(loginAction(formValues));
    if (isUserAuthenticated) {
      await dispatch(getUserInfoAction());
    }
    //todo add failed login notification
  };

  useEffect(() => {
    trackEvent(AnalyticsEvent.PageViewEvent, {
      name: LOGIN_PAGE,
    });
  }, []);

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate(OVERVIEW);
    }
  }, [navigate, isUserAuthenticated]);

  return (
    <StyledGridCenterAlign container>
      <Grid item xs={12}>
        <StyledBoxSpace space={8} />
        <GroovLogo />
      </Grid>
      <Grid item xs={12}>
        <StyledBoxSpace space={4} />
        <img src={adminImage} data-testid={LOGIN_ADMIN_IMAGE} />
      </Grid>
      <Grid item xs={12}>
        <StyledBoxSpace space={4} />
        <Typography variant="h1">{SIGN_IN}</Typography>
      </Grid>
      <Grid item xs={1} sm={3} md={4.5}></Grid>
      <Grid item xs={10} sm={6} md={3}>
        <LoginForm submitForm={submitForm} />
      </Grid>
      <Grid item xs={1} sm={3} md={4.5}></Grid>
    </StyledGridCenterAlign>
  );
};

export default Login;
