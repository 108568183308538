import { PillarScoreCard } from "../types/PillarScoreCard";
import { Pillar } from "../types/enums/Pillar";
import { Score } from "../types/enums/Score";
import { SixPillarsReportItem } from "../types/SixPillarsReportItem";
import { Sorting } from "../types/enums/Sorting";
import { WellbeingMetricDetails } from "../types/WellbeingMetricDetails";
import { PillarGrade } from "../types/enums/PillarGrade";
import { PillarGradedScoreCard } from "../types/PillarGradedScoreCard";

export const SortSixPillarsReportItems = (
  reportItems: SixPillarsReportItem[],
  sorting: Sorting
): SixPillarsReportItem[] => {
  return reportItems.slice().sort((a, b) => {
    switch (sorting) {
      case Sorting.FrequencyDescending:
        return b.rating - a.rating || a.index - b.index;
      case Sorting.Pillars:
        return (
          Pillar[a.pillar as keyof typeof Pillar] -
            Pillar[b.pillar as keyof typeof Pillar] || a.index - b.index
        );
      default:
        return a.rating - b.rating || a.index - b.index;
    }
  });
};

export const MapToWellbeingItems = (
  reportItems: SixPillarsReportItem[],
  expanded: boolean
): WellbeingMetricDetails[] => {
  return reportItems.map((p) => {
    return {
      key: p.key,
      title: p.title,
      rating: p.rating,
      pillar: p.pillar,
      details: p.details,
      expanded: expanded,
    };
  });
};

export const GroupByRating = (
  sixPillarsMetrics: SixPillarsReportItem[]
): { rating: number; score: number }[] => {
  const countByRating: { rating: number; score: number }[] = [];
  sixPillarsMetrics.reduce((res: any, value) => {
    if (!res[value.rating]) {
      res[value.rating] = {
        rating: value.rating,
        score: 0,
      };
      countByRating.push(res[value.rating]);
    }
    res[value.rating].score += 1;
    return res;
  }, {});
  return countByRating;
};

export const GetSixPillarsScoreCards = (
  sixPillarsMetrics: SixPillarsReportItem[]
): PillarScoreCard[] => {
  const scoreByPillar: PillarScoreCard[] = [];
  sixPillarsMetrics
    .filter((m) => m.pillar !== Pillar[Pillar.Wellbeing])
    .reduce((res: any, value) => {
      const pillar: Pillar = Pillar[value.pillar as keyof typeof Pillar];
      const rating: number = getScore(value.rating);
      if (!res[pillar]) {
        res[pillar] = {
          pillar: pillar,
          scoreSum: 0,
          noneOfTheTimes: 0,
        };
        scoreByPillar.push(res[pillar]);
      }
      res[pillar].scoreSum += rating;
      if (value.rating === 1) res[pillar].noneOfTheTimes++;
      return res;
    }, {});
  return scoreByPillar;
};

const getScore = (rating: number): number => {
  switch (rating) {
    case 1:
    case 2:
      return Score.Low;
    case 3:
      return Score.Medium;
    case 4:
    case 5:
      return Score.High;
    default:
      return 0;
  }
};

export const GradeScoreCards = (
  scoreCards: PillarScoreCard[]
): PillarGradedScoreCard[] => {
  const gradedScoreCards: PillarGradedScoreCard[] = scoreCards.map((s) => {
    return {
      ...s,
      grade: getScoreCardGrade(s.scoreSum, s.noneOfTheTimes),
    };
  });
  return gradedScoreCards;
};

const getScoreCardGrade = (
  score: number,
  noneOfTheTimes: number
): PillarGrade => {
  switch (score) {
    case 0:
    case 1:
    case 2:
    case 3:
      return PillarGrade.F;
    case 4:
      return PillarGrade.E;
    case 5:
      if (noneOfTheTimes > 1) return PillarGrade.E;
      else return PillarGrade.D;
    case 6:
      if (noneOfTheTimes > 0) return PillarGrade.D;
      else return PillarGrade.C;
    case 7:
      if (noneOfTheTimes > 0) return PillarGrade.D;
      else return PillarGrade.B;
    case 8:
      return PillarGrade.B;
    default:
      return PillarGrade.A;
  }
};
