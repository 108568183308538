import { Button, styled } from "@mui/material";
import theme from "../../styles/theme";

const StyledButtonCustom = styled(Button)(
  (props: { textColor: string; backgroundColor: string }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    color: props.textColor,
    backgroundColor: props.backgroundColor,
  })
);
export default StyledButtonCustom;
