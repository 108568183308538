export const formatUtcDateToUsDateString = (utcDate: Date) => {
  const utcDateString = addUtcTimeZoneToStringDate(utcDate.toString());
  return new Date(utcDateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const addUtcTimeZoneToStringDate = (utcDate: string) => {
  return `${utcDate}+00:00`;
};
