import React from "react";
import { GROOV_ICON_LOGO } from "../../constants/test-ids/groovIconLogo.ids";

const GroovIconLogo = () => {
  return (
    <svg
      data-testid={GROOV_ICON_LOGO}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_842_947)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#006AFF"
        />
        <path
          d="M27.2368 25.8173C27.0549 25.5264 26.764 25.2718 26.4368 25.09C26.1822 24.9446 25.8913 24.9082 25.6004 24.9082C25.2368 24.9082 24.9095 25.0173 24.5822 25.1991C24.2913 25.3809 24.0368 25.6355 23.8549 25.9627C23.4913 26.7264 22.9458 27.3082 22.2549 27.7446C21.6004 28.1809 20.8004 28.3991 19.964 28.3991C19.1277 28.3991 18.364 28.1446 17.6731 27.7446C17.0186 27.3082 16.4731 26.7264 16.0731 25.9627C15.8913 25.6355 15.6368 25.3809 15.3458 25.1991C15.0549 25.0173 14.6913 24.9082 14.3277 24.9082C14.0368 24.9082 13.7458 24.9809 13.4913 25.09C13.164 25.2718 12.8731 25.49 12.6913 25.8173C12.5095 26.1082 12.4004 26.4718 12.4004 26.8355C12.4004 27.1264 12.4731 27.4173 12.5822 27.6718C13.6004 29.7446 15.3822 31.2355 17.4913 31.89L17.7822 31.9627C18.4731 32.1446 19.164 32.2537 19.8913 32.2537H19.9277H19.964C20.6913 32.2537 21.4186 32.1446 22.0731 31.9627L22.364 31.89C24.4731 31.2355 26.2549 29.7446 27.2731 27.6718C27.4186 27.3809 27.4549 27.09 27.4549 26.8355C27.5277 26.4718 27.4186 26.1082 27.2368 25.8173Z"
          fill="#74E4C6"
        />
        <path
          d="M16.9456 13.2358C17.7456 12.4358 18.8002 11.9631 20.0002 11.9631C21.2002 11.9631 22.2911 12.4358 23.0547 13.2358C23.8184 13.9994 24.2911 15.054 24.3274 16.2176V16.3631C24.3274 17.5267 23.8184 18.5813 23.0547 19.3449C22.2547 20.1449 21.2002 20.6176 20.0002 20.6176C18.8002 20.6176 17.7093 20.1449 16.9456 19.3449C16.1456 18.5449 15.6729 17.4903 15.6729 16.2903C15.6729 15.0903 16.1456 13.9994 16.9456 13.2358ZM28.182 9.67216C28.182 9.16307 27.9638 8.65398 27.6365 8.3267C27.3093 7.99943 26.8002 7.78125 26.2911 7.78125C25.7456 7.78125 25.2729 7.99943 24.9093 8.3267C24.6184 8.61761 24.4365 8.98125 24.3638 9.38125C23.0911 8.58125 21.6002 8.14489 20.0002 8.14489C17.7456 8.14489 15.7093 9.05398 14.2184 10.5449C12.7274 12.0358 11.8184 14.0722 11.8184 16.3267C11.8184 18.5813 12.7274 20.6176 14.2184 22.1085C15.7093 23.5994 17.7456 24.5085 20.0002 24.5085C22.2547 24.5085 24.3274 23.5994 25.782 22.1085C27.1638 20.7267 28.0365 18.8722 28.1456 16.8358C28.1456 16.7631 28.182 16.654 28.182 16.5812V9.67216Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_842_947">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GroovIconLogo;
