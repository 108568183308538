import Countly from "countly-sdk-web";
import { AnalyticsEvent } from "../types/enums/AnalyticsEvent";
import { ROUTES_TO_IGNORE } from "../constants/analytics.constants";

const SIX_PILLARS_REPORT_APP_VERSION = 0.1;
const SIX_PILLARS_REPORT_ANALYTICS_KEY =
  process.env.REACT_APP_ANALYTICS_API_KEY || "";
const SIX_PILLARS_REPORT_ANALYTICS_URL =
  process.env.REACT_APP_ANALYTICS_API_URL || "";

class AnalyticsConfiguration {
  public appVersion: number;
  public analyticsApiKey: string;
  public analyticsApiUrl: string;
  public pageRoutesToIgnore?: string[];

  constructor(
    appVersion: number,
    analyticsApiKey: string,
    analyticsApiUrl: string,
    pageRoutesToIgnore?: string[]
  ) {
    this.appVersion = appVersion;
    this.analyticsApiKey = analyticsApiKey;
    this.analyticsApiUrl = analyticsApiUrl;
    this.pageRoutesToIgnore = pageRoutesToIgnore;
  }
}
const analyticsConfig = new AnalyticsConfiguration(
  SIX_PILLARS_REPORT_APP_VERSION,
  SIX_PILLARS_REPORT_ANALYTICS_KEY,
  SIX_PILLARS_REPORT_ANALYTICS_URL,
  ROUTES_TO_IGNORE
);

class AnalyticsHelper {
  private static instance: AnalyticsHelper;
  countly: any;

  static getInstance() {
    try {
      if (!AnalyticsHelper.instance) {
        AnalyticsHelper.instance = new AnalyticsHelper();
      }

      if (!AnalyticsHelper.instance.countly) {
        AnalyticsHelper.instance.initCountly(analyticsConfig);
      }
      return AnalyticsHelper.instance;
    } catch (error) {
      return {
        trackEvent: () => {
          return;
        },
        trackPageView: () => {
          return;
        },
        trackUser: () => {
          return;
        },
      };
    }
  }

  private initCountly = (configuration: AnalyticsConfiguration) => {
    this.countly = Countly || {};
    this.countly.q = Countly.q || [];
    this.countly.app_key = analyticsConfig.analyticsApiKey;
    this.countly.url = analyticsConfig.analyticsApiUrl;
    this.countly.app_version = configuration.appVersion.toString();
    console.log(`Initialising Countly at ${this.countly.url}`);
    this.countly.q.push(["track_pageview", configuration.pageRoutesToIgnore]);
    this.countly.q.push(["track_sessions"]);
    this.countly.q.push(["track_clicks"]);
    this.countly.q.push(["track_scrolls"]);
    this.countly.remote_config = true;
    this.countly.init();
  };

  setUserProperty = (key: string, value: string) => {
    this.countly.q.push(["userData.set", key, value]);
  };

  trackEvent = (
    eventName: AnalyticsEvent,
    extraInfo: { [index: string]: string | number | boolean }
  ) => {
    this.countly.q.push([
      "add_event",
      {
        key: eventName,
        segmentation: { ...extraInfo },
      },
    ]);
  };

  trackUser = (userInfo: { [index: string]: string | number }) => {
    this.countly.q.push([
      "user_details",
      {
        custom: {
          ...userInfo,
        },
      },
    ]);
  };

  loadRemoteConfig = (callback: (err: any, config: any) => void) => {
    console.log("Countly fetching remote config called....");
    this.countly.fetch_remote_config(callback);
  };
}

export const trackEvent = (
  eventName: AnalyticsEvent,
  extraInfo: { [index: string]: string | number | boolean }
) => {
  AnalyticsHelper.getInstance().trackEvent(eventName, extraInfo);
};

export const trackView = (
  eventName: AnalyticsEvent,
  extraInfo: { [index: string]: string | number | boolean }
) => {
  AnalyticsHelper.getInstance().trackEvent(eventName, extraInfo);
};

export const trackUser = (userId: string, orgId: string) => {
  AnalyticsHelper.getInstance().trackUser({ userId, orgId });
};

export default AnalyticsHelper;
