import { Grid } from "@mui/material";
import React from "react";
import { APP_LAYOUT } from "../../constants/test-ids/layout.ids";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import theme from "../../styles/theme";
import Footer from "./Footer";
import Header from "./Header";

interface Props {
  children?: React.ReactNode;
}

const Layout = (props: Props) => {
  const { currentBreakpoint, columns, mdScreen, xsScreenAndSmScreen } =
    useAppMediaQuery();
  // console.log("🚀 ", currentBreakpoint(), " ", columns); //todo remove, for debugging only

  return (
    <Grid container data-testid={APP_LAYOUT} justifyContent="center">
      <Grid item xs={12} padding={theme.spacing(4)}>
        <Header />
      </Grid>
      <Grid
        item
        sm={12}
        md={10}
        margin={
          mdScreen
            ? `0 ${theme.spacing(4)}`
            : xsScreenAndSmScreen
            ? `0 ${theme.spacing(2)}`
            : `0 0`
        }
        sx={{
          "@media (min-width:1200px)": {
            // lg-xl
            maxWidth: "1120px",
            // backgroundColor: "pink", //todo remove, for debugging only
          },
          "@media (max-width: 1199px) and (min-width: 900px)": {
            // md
            maxWidth: "736px", //as per designs this is smaller than sm screen
            // backgroundColor: "#ffffb2", //todo remove, for debugging only
          },
          "@media (max-width: 899px) and (min-width: 600px)": {
            // sm
            minWidth: "0px",
            maxWidth: "835px",
            // backgroundColor: "grey", //todo remove, for debugging only
          },
          "@media (max-width: 599px)": {
            // xs
            minWidth: "0px",
            maxWidth: "568px",
            // backgroundColor: "#c8f7c8", //todo remove, for debugging only
          },
        }}
      >
        <main>{props.children}</main>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Layout;
