import * as React from "react";
import SelectionForm from "./SelectionForm";
import {
  COMPARE_TO_LABEL,
  SURVEY_LABEL,
} from "../constants/components/surveyComparison.constants";
import { StyledPaperSelection } from "./styled/StyledPaperSelection";
import { SURVEY_COMPARISON_SELECTION } from "../constants/test-ids/surveyComparison.ids";
import { SelectChangeEvent } from "@mui/material/Select";
import { SelectionItem } from "../types/SelectionItem";
import { useAppMediaQuery } from "../hooks/useAppMediaQuery";

interface Props {
  surveyRecords: SelectionItem[];
  comparisonSurveyRecords: SelectionItem[];
  selection: string;
  comparisonSelection: string;
  handleSelectionChange: (event: SelectChangeEvent) => void;
  handleComparisonChange: (event: SelectChangeEvent) => void;
  comparisonEnabled: boolean;
}

const SurveyComparison: React.FC<Props> = ({
  surveyRecords,
  comparisonSurveyRecords,
  selection,
  comparisonSelection,
  handleSelectionChange,
  handleComparisonChange,
  comparisonEnabled,
}) => {
  const { xsScreen } = useAppMediaQuery();
  return (
    <StyledPaperSelection
      elevation={0}
      variant={"outlined"}
      data-testid={SURVEY_COMPARISON_SELECTION}
    >
      <SelectionForm
        items={surveyRecords}
        label={SURVEY_LABEL}
        selection={selection}
        handleChange={handleSelectionChange}
        fullWidth={xsScreen}
      />
      {comparisonEnabled && (
        <SelectionForm
          items={comparisonSurveyRecords}
          label={COMPARE_TO_LABEL}
          selection={comparisonSelection}
          handleChange={handleComparisonChange}
        />
      )}
    </StyledPaperSelection>
  );
};

export default SurveyComparison;
