import styled from "@emotion/styled";
import { Paper } from "@mui/material";

const StyledPaperCustom = styled(Paper)(
  (props: {
    padding: string;
    borderRadius: string;
    color: string;
    width?: string;
    maxWidth?: string;
    borderColor?: string;
    opacity?: string;
    margin?: string;
  }) => ({
    padding: props.padding,
    borderRadius: props.borderRadius,
    backgroundColor: props.color,
    borderColor: props.borderColor ?? props.color,
    width: props.width,
    maxWidth: props.maxWidth,
    opacity: props.opacity,
    margin: props.margin,
  })
);

export default StyledPaperCustom;
