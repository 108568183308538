import React from "react";

const IconAscending = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4004 7C13.4004 6.44771 13.8481 6 14.4004 6H15.8004C16.3527 6 16.8004 6.44771 16.8004 7V7.4C16.8004 7.95228 16.3527 8.4 15.8004 8.4H14.4004C13.8481 8.4 13.4004 7.95228 13.4004 7.4V7ZM14.4004 13.2C13.8481 13.2 13.4004 12.7523 13.4004 12.2V11.8C13.4004 11.2477 13.8481 10.8 14.4004 10.8H18.2004C18.7527 10.8 19.2004 11.2477 19.2004 11.8V12.2C19.2004 12.7523 18.7527 13.2 18.2004 13.2H14.4004ZM13.4004 16.6C13.4004 16.0477 13.8481 15.6 14.4004 15.6H20.6004C21.1527 15.6 21.6004 16.0477 21.6004 16.6V17C21.6004 17.5523 21.1527 18 20.6004 18H14.4004C13.8481 18 13.4004 17.5523 13.4004 17V16.6Z"
      fill="#1C1C1C"
    />
    <path
      d="M7.25 6.75L7.25 16.6667"
      stroke="#1C1C1C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M10.084 14.5834L7.25065 17.25L4.41732 14.5834"
      stroke="#1C1C1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconAscending;
