import {
  Box,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import groovColors from "../styles/colors";
import { SelectionItem } from "../types/SelectionItem";
import {
  SELECTION_FORM,
  SELECTION_FORM_CHECK,
  SELECTION_FORM_ITEM,
  SELECTION_FORM_LABEL,
  SELECTION_FORM_MENU_ITEM,
  SELECTION_FORM_SELECT,
} from "../constants/test-ids/selectionForm.ids";
import theme from "../styles/theme";
import StyledExpandMoreIcon from "./styled/StyledExpandMoreIcon";
import { Check } from "@mui/icons-material";

interface Props {
  items: SelectionItem[];
  label: string;
  fullWidth?: boolean;
  selection: string;
  handleChange: (event: SelectChangeEvent) => void;
}

const StyledInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: theme.spacing(1.5),
    position: "relative",
    backgroundColor: groovColors.neutral[0],
    padding: theme.spacing(2),
    "&:focus": {
      borderRadius: theme.spacing(1.5),
      backgroundColor: groovColors.neutral[0],
    },
    border: `1px solid ${groovColors.blue[5]}`,
  },
}));

const SelectionForm: React.FC<Props> = ({
  items,
  label,
  fullWidth = false,
  selection,
  handleChange,
}) => {
  return (
    <FormControl
      sx={{ marginRight: 1 }}
      variant="standard"
      data-testid={SELECTION_FORM}
      fullWidth={fullWidth}
    >
      <Select
        data-testid={SELECTION_FORM_SELECT}
        value={selection}
        renderValue={(value) => {
          return (
            <Box
              sx={{
                display: "flex",
                marginRight: theme.spacing(2),
              }}
            >
              <Typography
                variant="body1"
                color={groovColors.neutral[80]}
                data-testid={SELECTION_FORM_LABEL}
              >
                {`${label}:`}&nbsp;
              </Typography>
              <Typography
                variant="button"
                color={groovColors.blue[130]}
                data-testid={SELECTION_FORM_ITEM}
              >
                {`${items.find((i) => i.key === value)?.value}`}
              </Typography>
            </Box>
          );
        }}
        onChange={handleChange}
        input={<StyledInput />}
        IconComponent={StyledExpandMoreIcon}
      >
        {items.map((item) => {
          return (
            <MenuItem
              value={item.key}
              key={item.key}
              data-testid={SELECTION_FORM_MENU_ITEM}
              sx={{
                margin: `0 ${theme.spacing(1)}`,
                borderRadius: theme.spacing(1),
                "&.Mui-selected": {
                  backgroundColor: `${groovColors.neutral[0]} !important`,
                },
                "&:hover": {
                  backgroundColor: `${groovColors.blue[5]} !important`,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: `${groovColors.blue[5]} !important`,
                },
              }}
            >
              <Grid container spacing={1}>
                {item.icon && <Grid item>{item.icon}</Grid>}
                <Grid item>
                  <Typography variant="body1" color={groovColors.neutral[80]}>
                    {item.value}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Grid container direction={"row-reverse"}>
                    {selection === item.key && (
                      <Check data-testid={SELECTION_FORM_CHECK} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectionForm;
