import { TextField } from "@mui/material";
import React from "react";
import theme from "../../styles/theme";

type Props = {
  InputProps?: object;
  [rest: string]: unknown;
};

const StyledTextField: React.FC<Props> = ({ InputProps = {}, ...rest }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        sx: {
          borderRadius: theme.spacing(8),
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        ...InputProps,
      }}
      {...rest}
    />
  );
};

export default StyledTextField;
