export const ROUTES_TO_IGNORE = ["/overview/*", "/breakdown/*"];

// Features
export const REPORT_BREAKDOWN = "ReportBreakdown";
export const REPORT_OVERVIEW = "ReportOverview";
export const LIFE_RING = "LifeRing";
export const LOGIN = "Login";
export const SIGN_OUT = "SignOut";

// View types
export const PAGE_SUFFIX = "Page";
export const MODAL_SUFFIX = "Modal";

// Pages
export const REPORT_OVERVIEW_PAGE = `${REPORT_BREAKDOWN}${PAGE_SUFFIX}`;
export const REPORT_BREAKDOWN_PAGE = `${REPORT_OVERVIEW}${PAGE_SUFFIX}`;
export const LOGIN_PAGE = `${LOGIN}${PAGE_SUFFIX}`;
export const LIFE_RING_MODAL = `${LIFE_RING}${MODAL_SUFFIX}`;

// UI element types
export const COMPARER = "Comparer";
export const FILTER = "Filter";
export const SORTER = "Sorter";
export const BUTTON = "Button";
export const ACCORDION = "Accordion";

// UI Elements
export const OPEN_LIFE_RING = "OpenLifeRing";
export const CLOSE_LIFE_RING = "OpenLifeRing";
export const QUESTION_ACCORDION = "QuestionAccordion";
export const OVERVIEW_PILLAR_LINK = "OverviewPillarLink";
export const REPORT_OVERVIEW_FILTER = "ReportOverviewFilter";
export const REPORT_OVERVIEW_COMPARER = "ReportOverviewComparer";
export const COLLAPSE_ALL_REPORT_BREAKDOWN_ACCORDION_BUTTON =
  "CollapseAllReportBreakdownAccordionButton";
export const EXPAND_ALL_REPORT_BREAKDOWN_ACCORDION_BUTTON =
  "ExpandAllReportBreakdownAccordionButton";
export const REPORT_BREAKDOWN_FILTER = "ReportBreakdownFilter";
export const REPORT_BREAKDOWN_SORTER = "ReportBreakdownSorter";
export const APP_STORE_ELEMENT = "AppStoreLink";
export const PLAY_STORE_ELEMENT = "PlayStoreLink";
