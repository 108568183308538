import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ErrorPage from "./ErrorPage";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Overview from "./components/Overview";
import Breakdown from "./components/Breakdown";
import {
  BREAKDOWN,
  LOGIN,
  OVERVIEW,
  ANONYMOUS_OVERVIEW,
  ANONYMOUS_BREAKDOWN,
  ROOT,
} from "./constants/routes";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./store";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={LOGIN} element={<Login />} />
      <Route element={<App />} errorElement={<ErrorPage />}>
        <Route element={<ProtectedRoute />}>
          <Route path={ROOT} element={<Overview />} />
          <Route path={OVERVIEW} element={<Overview />} />
          <Route path={BREAKDOWN} element={<Breakdown />} />
        </Route>
        <Route path={ANONYMOUS_OVERVIEW} element={<Overview />} />
        <Route path={ANONYMOUS_BREAKDOWN} element={<Breakdown />} />
      </Route>
    </>
  )
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
