import {
  FetchBaseQueryError,
  BaseQueryApi,
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { RootState } from "..";
import {
  refreshLoginAction,
  logoutAction,
  getUserInfoAction,
} from "../actions/authActions";

export const refreshLoginAndRetryQuery = async (
  result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
  api: BaseQueryApi,
  args: string | FetchArgs,
  extraOptions: any,
  baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    any,
    FetchBaseQueryMeta
  >
) => {
  await api.dispatch(refreshLoginAction());
  await api.dispatch(getUserInfoAction());

  const auth = (api.getState() as RootState).auth;

  if (!auth.loading && !auth.error) {
    result = await baseQuery(args, api, extraOptions);
  } else {
    api.dispatch(logoutAction());
  }

  return result;
};
