import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { SIX_PILLARS_REPORT_API } from "../../config/api.config";
import { SixPillarsReport } from "../../types/SixPillarsReport";
import {
  addApiKeyHeader,
  addAuthorizationHeader,
} from "../../utils/apiHelpers";
import { refreshLoginAndRetryQuery } from "./commonService";
import { SIX_PILLARS_API_KEY } from "../../config/app.config";
import { SerializedError } from "@reduxjs/toolkit";

const baseQuery = fetchBaseQuery({
  baseUrl: SIX_PILLARS_REPORT_API.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.accessToken;
    addAuthorizationHeader(headers, accessToken);
    addApiKeyHeader(headers, SIX_PILLARS_API_KEY);
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    result = await refreshLoginAndRetryQuery(
      result,
      api,
      args,
      extraOptions,
      baseQuery
    );
  }

  return result;
};

export const sixPillarsReportApi = createApi({
  reducerPath: "sixPillarsReportApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSixPillarsReportsByEmailAddress: builder.query<
      SixPillarsReport[],
      string
    >({
      query: (emailAddress) =>
        `${SIX_PILLARS_REPORT_API.ENDPOINTS.SIX_PILLARS_REPORTS}?emailAddress=${emailAddress}`,
    }),
    getSixPillarsReportsByResponseId: builder.query<SixPillarsReport, string>({
      query: (responseId) =>
        `${SIX_PILLARS_REPORT_API.ENDPOINTS.SIX_PILLARS_ANONYMOUS_REPORT}/${responseId}`,
    }),
  }),
});

export const getReports = (
  emailAddress: string,
  responseId: string | undefined,
  skip: boolean
): {
  data: SixPillarsReport[];
  error: FetchBaseQueryError | SerializedError | undefined;
  isLoading: boolean;
  isUninitialized: boolean;
} => {
  const getReportsByResponseId = (responseId: string) => {
    const {
      data: report,
      error,
      isLoading,
      isUninitialized,
    } = useGetSixPillarsReportsByResponseIdQuery(responseId);

    return {
      data: report ? [report] : [],
      error,
      isLoading,
      isUninitialized,
    };
  };

  const { data, error, isLoading, isUninitialized } = responseId
    ? getReportsByResponseId(responseId)
    : useGetSixPillarsReportsByEmailAddressQuery(emailAddress, { skip });

  return {
    data: data ? data : [],
    error,
    isLoading,
    isUninitialized,
  };
};

export const {
  useGetSixPillarsReportsByEmailAddressQuery,
  useGetSixPillarsReportsByResponseIdQuery,
} = sixPillarsReportApi;
