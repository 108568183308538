import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import groovColors from "../../styles/colors";

type Props = {
  [rest: string]: unknown;
};

const StyledCloseIcon: React.FC<Props> = ({ ...rest }) => {
  return (
    <CloseIcon
      style={{
        cursor: "pointer",
        color: groovColors.neutral[0],
      }}
      {...rest}
    />
  );
};

export default StyledCloseIcon;
