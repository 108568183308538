import { NAV_OVERVIEW, NAV_DETAILS } from "../constants/navigation.constants";
import { OVERVIEW, BREAKDOWN } from "../constants/routes";
import { NavigationItem } from "../types/NavigationItem";

export const getNavigationItems = (responseId: string | undefined) => {
  const overviewNavigationItems: NavigationItem[] = [
    {
      name: NAV_OVERVIEW,
      url: responseId ? `${OVERVIEW}/${responseId}` : OVERVIEW,
    },
    {
      name: NAV_DETAILS,
      url: responseId ? `${BREAKDOWN}/${responseId}` : BREAKDOWN,
    },
  ];

  return overviewNavigationItems;
};
