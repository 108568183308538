import { useMediaQuery } from "@mui/material";
import theme from "../styles/theme";

export const useAppMediaQuery = () => {
  const lgScreenAndAbove = useMediaQuery(theme.breakpoints.up("lg")); // breakpoints lg and xl
  const smScreenAndMdScreen = useMediaQuery(
    theme.breakpoints.between("sm", "lg")
  ); // breakpoints  sm and md
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm")); // breakpoint xs

  const mdScreen = useMediaQuery(theme.breakpoints.only("md")); // breakpoint md
  const xsScreenAndSmScreen = useMediaQuery(theme.breakpoints.down("md")); // xs and sm (if true, screen should NOT have spacer columns left and right. see Layout.tsx)
  const mdScreenAndBelow = useMediaQuery(theme.breakpoints.down("lg")); // xs, sm and md (if true, screen should have text/items aligned canter. see Banner.tsx)

  const currentBreakpoint = (): string => {
    let breakpoint = "";
    theme.breakpoints.keys.forEach((b) => {
      if (useMediaQuery(theme.breakpoints.only(b))) {
        breakpoint = b.toString();
      }
    });
    return breakpoint;
  };

  return {
    lgScreenAndAbove,
    smScreenAndMdScreen,
    xsScreen,
    mdScreen,
    xsScreenAndSmScreen,
    mdScreenAndBelow,
    currentBreakpoint,
    columns: xsScreen ? 4 : smScreenAndMdScreen ? 8 : 12, //column numbers per screen size as per designs
  };
};
