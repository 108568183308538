import { API_KEY, AUTHORIZATION, BEARER } from "../constants/auth.constants";

export const addAuthorizationHeader = (
  headers: Headers,
  accessToken?: string | null
) => {
  if (accessToken && accessToken !== "") {
    headers.set(AUTHORIZATION, `${BEARER} ${accessToken}`);
  }
  return headers;
};

export const addApiKeyHeader = (headers: Headers, apiKey: string) => {
  headers.set(API_KEY, apiKey);

  return headers;
};
