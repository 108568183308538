import { Box } from "@mui/material";
import React from "react";
import theme from "../../styles/theme";

interface Props {
  space: number;
}

const StyledBoxSpace: React.FC<Props> = ({ space }) => {
  return (
    <Box
      sx={{
        marginTop: theme.spacing(space),
      }}
    />
  );
};

export default StyledBoxSpace;
