import React from "react";
import {
  LOADING_CARD,
  LOADING_CIRCULAR_PROGRESS,
} from "../../constants/test-ids/loadingCard.ids";
import CircularProgress from "@mui/material/CircularProgress";
import StyledPaper from "../styled/StyledPaper";

const LoadingCard = () => {
  return (
    <StyledPaper elevation={0} data-testid={LOADING_CARD}>
      <CircularProgress
        color="inherit"
        data-testid={LOADING_CIRCULAR_PROGRESS}
      />
    </StyledPaper>
  );
};

export default LoadingCard;
