import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  EVERY_DAY,
  MORE,
  PILLAR_CONTENT_BLOCKS,
  PILLAR_DEFINITIONS,
  PILLAR_NAME,
  SIX_PILLARS_WELLBEING_DEFINITION_HELPTEXT,
} from "../../constants/components/pillarBoxes.constants";
import {
  PILLAR_HIGHLIGHT_TEXT_ID,
  PILLAR_MESSAGE_HELP_ID,
  PILLAR_MESSAGE_ID,
  PILLAR_MESSAGE_LEARN_MORE_ID,
  PILLAR_MESSAGE_TEXT_ID,
} from "../../constants/test-ids/pillarMessage.ids";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";
import { ContentBlockText } from "../../types/PillarContentBlockText";
import { Pillar } from "../../types/enums/Pillar";
import { PillarGradedScoreCard } from "../../types/PillarGradedScoreCard";
import StyledPaperCustom from "../styled/StyledPaperCustom";
import PillarBadge from "./PillarBadge";
import StyledButtonCustom from "../styled/StyledButtonCustom";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import { PillarGrade } from "../../types/enums/PillarGrade";
import {
  PILLAR_MESSAGE_LINK_BUTTON,
  PILLAR_MESSAGE_LINK_BUTTON_ARROW,
} from "../../constants/components/pillarMessage.constants";
import { trackEvent } from "../../analytics/AnalyticsPublisher";
import { AnalyticsEvent } from "../../types/enums/AnalyticsEvent";
import { Answer } from "../../types/enums/Answer";
import { toBoldFormat } from "../../utils/stringHelpers";
import { PillarDefinition } from "../../types/PillarDefinition";
import {
  BUTTON,
  OVERVIEW_PILLAR_LINK,
} from "../../constants/analytics.constants";
import TwoColumnContainer from "../layout/TwoColumnContainer";
import StyledHelpOutlineIcon from "../styled/StyledHelpOutlineIcon";
import { Helptext } from "../../types/Helptext";
import HelptextDrawer from "../helptext/HelptextDrawer";

interface Props {
  scoreCard: PillarGradedScoreCard;
}

const PillarMessage: React.FC<Props> = ({ scoreCard }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [helptext, setHelptext] = useState<Helptext>();
  const { xsScreenAndSmScreen } = useAppMediaQuery();

  const contentBlockText: ContentBlockText = PILLAR_CONTENT_BLOCKS[
    scoreCard.grade
  ] as ContentBlockText;
  const showPillarInfo = [PillarGrade.E, PillarGrade.F].includes(
    scoreCard.grade
  );

  let message = "";
  let link = "#";
  const pillar = Pillar[scoreCard.pillar];
  if (showPillarInfo) {
    const pillarDefinition: PillarDefinition = PILLAR_DEFINITIONS.find(
      (p) => p.Pillar === pillar
    ) as PillarDefinition;
    message = pillarDefinition.Definition;
    link = pillarDefinition.Link;
  } else {
    message = contentBlockText.MESSAGE;
    message = toBoldFormat(message, MORE);
    message = toBoldFormat(message, EVERY_DAY);
    for (const answer of Object.values(Answer)) {
      message = toBoldFormat(message, answer.toLowerCase());
    }
    message = message.replace(PILLAR_NAME, pillar);
  }

  const handleLearnMore = (): void => {
    trackEvent(AnalyticsEvent.OnPressedEvent, {
      elementType: BUTTON,
      elementName: OVERVIEW_PILLAR_LINK,
      pillar: Pillar[scoreCard.pillar],
    });
    window.open(link, "_blank");
  };

  const pillarHelpText: Helptext = {
    Title: helptext ? helptext.Title : "",
    Content: helptext
      ? helptext.Content.filter((f) => f.Header === Pillar[scoreCard.pillar])
      : [],
  };

  return (
    <StyledPaperCustom
      data-testid={PILLAR_MESSAGE_ID}
      elevation={0}
      variant="outlined"
      padding={theme.spacing(2.5)}
      borderRadius={theme.spacing(3)}
      color={groovColors.neutral[0]}
      borderColor={groovColors.neutral[20]}
    >
      <Grid container>
        <Grid item xs={12}>
          <TwoColumnContainer
            firstColumn={<PillarBadge pillar={Pillar[scoreCard.pillar]} />}
            secondColumn={
              <StyledHelpOutlineIcon
                data-testid={PILLAR_MESSAGE_HELP_ID}
                onClick={() => {
                  setOpenDrawer(true);
                  setHelptext(
                    SIX_PILLARS_WELLBEING_DEFINITION_HELPTEXT as Helptext
                  );
                }}
              />
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            margin: `${theme.spacing(2)} 0`,
          }}
        >
          <StyledPaperCustom
            elevation={0}
            color={contentBlockText.COLOR}
            padding={theme.spacing(2)}
            borderRadius={theme.spacing(1)}
          >
            <Typography variant="button" data-testid={PILLAR_HIGHLIGHT_TEXT_ID}>
              {contentBlockText.HIGHLIGHT}
            </Typography>
          </StyledPaperCustom>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            data-testid={PILLAR_MESSAGE_TEXT_ID}
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></Typography>
        </Grid>
        {showPillarInfo && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: `${theme.spacing(2)}`,
            }}
          >
            <StyledButtonCustom
              data-testid={PILLAR_MESSAGE_LEARN_MORE_ID}
              disableElevation
              variant="contained"
              textColor={groovColors.blue[130]}
              backgroundColor={groovColors.blue[5]}
              fullWidth={xsScreenAndSmScreen}
              onClick={handleLearnMore}
            >
              {PILLAR_MESSAGE_LINK_BUTTON}
              {xsScreenAndSmScreen ? "" : PILLAR_MESSAGE_LINK_BUTTON_ARROW}
            </StyledButtonCustom>
          </Grid>
        )}
      </Grid>
      <HelptextDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        helptext={pillarHelpText}
      />
    </StyledPaperCustom>
  );
};

export default PillarMessage;
