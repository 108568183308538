import React from "react";
import { Drawer } from "@mui/material";
import { useAppMediaQuery } from "../../hooks/useAppMediaQuery";
import theme from "../../styles/theme";
import groovColors from "../../styles/colors";

type Props = {
  children?: React.ReactNode;
  [rest: string]: unknown;
};

const StyledDrawer: React.FC<Props> = ({ children, ...rest }) => {
  const { mdScreen, lgScreenAndAbove } = useAppMediaQuery();
  const width = lgScreenAndAbove ? "30%" : mdScreen ? "75%" : "100%";

  return (
    <Drawer
      variant="temporary"
      transitionDuration={{ enter: 500, exit: 300 }}
      anchor={"right"}
      elevation={0}
      PaperProps={{
        sx: {
          width: width,
          padding: theme.spacing(2),
          backgroundColor: groovColors.blue[100],
          borderRadius: 0,
        },
      }}
      {...rest}
    >
      {children}
    </Drawer>
  );
};

export default StyledDrawer;
