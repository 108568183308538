export const SIX_PILLARS_REPORT_API = {
  BASE_URL: process.env.REACT_APP_SIX_PILLARS_API_URL,
  ENDPOINTS: {
    SIX_PILLARS_REPORTS: "/Reports",
    SIX_PILLARS_ANONYMOUS_REPORT: "/ReportsAnonymous",
  },
};

export const CONTENT_API = {
  BASE_URL: process.env.REACT_APP_CONTENT_API_URL,
  ENDPOINTS: {
    ORGANISATIONS: "/content/organizations",
  },
};

export const IDENTITY_SERVICE_API = {
  BASE_URL: process.env.REACT_APP_IDENTITY_SERVICE_URL,
  ENDPOINTS: {
    TOKEN: "/connect/token",
    USERINFO: "/connect/userinfo",
  },
};
