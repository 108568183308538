import { Grid, Typography } from "@mui/material";
import React from "react";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";
import { Helptext } from "../../types/Helptext";
import TwoColumnContainer from "../layout/TwoColumnContainer";
import StyledCloseIcon from "../styled/StyledCloseIcon";
import StyledDrawer from "../styled/StyledDrawer";
import {
  HELPTEXT_BODY_ID,
  HELPTEXT_CLOSE_ID,
  HELPTEXT_DRAWER_ID,
  HELPTEXT_SUBTITLE_ID,
  HELPTEXT_TITLE_ID,
} from "../../constants/test-ids/helptextDrawer.ids";
import StyledPaperCustom from "../styled/StyledPaperCustom";

interface Propss {
  openDrawer: boolean;
  setOpenDrawer: (d: boolean) => void;
  helptext: Helptext | undefined;
}

const HelptextDrawer: React.FC<Propss> = ({
  openDrawer,
  setOpenDrawer,
  helptext,
}) => {
  return (
    <StyledDrawer
      data-testid={HELPTEXT_DRAWER_ID}
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TwoColumnContainer
            firstColumn={
              <Typography
                variant="h2"
                color={groovColors.neutral[0]}
                data-testid={HELPTEXT_TITLE_ID}
              >
                {helptext?.Title}
              </Typography>
            }
            secondColumn={
              <StyledCloseIcon
                data-testid={HELPTEXT_CLOSE_ID}
                onClick={() => setOpenDrawer(false)}
              />
            }
          />
        </Grid>
        {helptext &&
          helptext.Content &&
          helptext.Content.map((h) => {
            return (
              <StyledPaperCustom
                key={h.Header}
                color={groovColors.neutral[0]}
                padding={theme.spacing(2)}
                margin={theme.spacing(1)}
                borderColor={groovColors.neutral[0]}
                borderRadius={""}
                elevation={0}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color={groovColors.blue[130]}
                    dangerouslySetInnerHTML={{
                      __html: h.Header,
                    }}
                    data-testid={HELPTEXT_SUBTITLE_ID}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color={groovColors.neutral[80]}
                    dangerouslySetInnerHTML={{
                      __html: h.Body,
                    }}
                    data-testid={HELPTEXT_BODY_ID}
                  />
                </Grid>
              </StyledPaperCustom>
            );
          })}
      </Grid>
    </StyledDrawer>
  );
};

export default HelptextDrawer;
