import React from "react";
import { LIFE_RING_ID } from "../../constants/test-ids/lifeRing.ids";

const LifeRingColored = ({
  height = 48,
  width = 48,
  ...otherProps
}: {
  height?: number;
  width?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    {...otherProps}
    data-testid={LIFE_RING_ID}
  >
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={12} cy={12} r={11} />
      <path
        fill="#FF564F"
        fillRule="nonzero"
        d="M8 1h7.364l-2.455 6.136h-2.454zM8.318 22.682h7.364l-2.455-6h-2.454zM22.682 15.682V8.318l-6 2.455v2.454zM1.318 15.682V8.318l6 2.455v2.454z"
      />
      <path
        d="M12 23.045C5.9 23.045.955 18.1.955 12S5.9.955 12 .955 23.045 5.9 23.045 12 18.1 23.045 12 23.045zm0-6.136a4.91 4.91 0 100-9.818 4.91 4.91 0 000 9.818z"
        stroke="#4D7AFF"
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default LifeRingColored;
