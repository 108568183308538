import { Typography } from "@mui/material";
import React from "react";
import { PILLAR_STICKER_ID } from "../constants/test-ids/pillarSticker.ids";
import groovColors from "../styles/colors";
import theme from "../styles/theme";
import StyledPaperCustom from "./styled/StyledPaperCustom";

interface Props {
  pillar: string;
}

const PillarSticker: React.FC<Props> = ({ pillar }) => {
  return (
    <StyledPaperCustom
      color={groovColors.neutral[10]}
      padding={`${theme.spacing(0.5)} ${theme.spacing(1)}`}
      borderRadius={theme.spacing(1)}
      elevation={0}
    >
      <Typography variant="h5" data-testid={PILLAR_STICKER_ID}>
        {pillar}
      </Typography>
    </StyledPaperCustom>
  );
};

export default PillarSticker;
