import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";

export const StyledPaperSelection = styled(Paper)({
  backgroundColor: groovColors.blue[1],
  borderColor: groovColors.blue[1],
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1),
});
