import { Grid, Typography } from "@mui/material";
import React from "react";
import { FREE_CALL_TEXT } from "../../constants/components/lifeRingModal.constants";
import {
  FREE_CALL_TEXT_ID,
  HELP_LINE_ID,
  COUNTRY_ID,
  HELP_LINE_BOX_ID,
} from "../../constants/test-ids/helpLineBox.ids";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";
import StyledPaperCustom from "../styled/StyledPaperCustom";

interface HelpLineBox {
  country: string;
  helpLine: string;
}

const HelpLineBox: React.FC<HelpLineBox> = ({ country, helpLine }) => {
  return (
    <StyledPaperCustom
      elevation={0}
      color={groovColors.neutral[10]}
      padding={theme.spacing(2.5)}
      borderRadius={theme.spacing(1.5)}
      sx={{
        marginTop: theme.spacing(2),
      }}
      data-testid={HELP_LINE_BOX_ID}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography
            variant="button"
            data-testid={COUNTRY_ID}
            color={groovColors.neutral[80]}
          >
            {country}
          </Typography>
          <Typography
            variant="body1"
            data-testid={FREE_CALL_TEXT_ID}
            color={groovColors.neutral[80]}
          >
            {FREE_CALL_TEXT}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          flexWrap={"wrap"}
          alignContent={"center"}
        >
          <Grid container flexDirection={"row-reverse"}>
            <Typography
              variant="button"
              color={groovColors.blue[130]}
              data-testid={HELP_LINE_ID}
            >
              {helpLine}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaperCustom>
  );
};

export default HelpLineBox;
