import { Typography } from "@mui/material";
import React from "react";
import {
  ERROR_TITLE_GENERIC,
  ERROR_BODY_GENERIC,
} from "../../constants/error.constants";
import {
  ERROR_CARD,
  ERROR_TITLE_ID,
  ERROR_SUBTITLE_ID,
} from "../../constants/test-ids/errorCard.ids";
import StyledPaperNotes from "../styled/StyledPaperNotes";
import { IS_DEVELOPMENT } from "../../config/app.config";

interface Props {
  title?: string;
  body?: string;
  error: object;
}

const ErrorCard: React.FC<Props> = ({ title, body, error }) => {
  return (
    <StyledPaperNotes elevation={0} data-testid={ERROR_CARD}>
      <Typography variant="h1" data-testid={ERROR_TITLE_ID}>
        {title ?? ERROR_TITLE_GENERIC}
      </Typography>
      <Typography variant="body1" data-testid={ERROR_SUBTITLE_ID}>
        {body ?? ERROR_BODY_GENERIC}
      </Typography>
      {IS_DEVELOPMENT && (
        <Typography variant="body1">{JSON.stringify(error)}</Typography>
      )}
    </StyledPaperNotes>
  );
};

export default ErrorCard;
