export const OVERVIEW_TITLE_ID = "OverviewTitleId";
export const OVERVIEW_WELLBEING_HEADER_ID = "OverviewWellbeingHeaderId";
export const OVERVIEW_WELLBEING_SUBHEADER_ID = "OverviewWellbeingSubheaderId";
export const OVERVIEW_SIX_PILLARS_HEADER_ID = "OverviewSixPillarsHeaderId";
export const OVERVIEW_SIX_PILLARS_SUBHEADER_ID =
  "OverviewSixPillarsSubheaderId";

export const SIX_PILLARS_WELLBEING_RESULT_HELPTEXT_ID =
  "SixPillarsWellbeingResultHelptextId";
export const SIX_PILLARS_WELLBEING_HELPTEXT_ID =
  "SixPillarsWellbeingHelptextId";
export const SIX_PILLARS_GENERAL_WELLBEING_HELPTEXT_ID =
  "SixPillarsGeneralWellbeingHelptextId";
