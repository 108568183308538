import React from "react";
import { PILLARS_ICON } from "../../constants/test-ids/icon.ids";

const IconPillars = () => (
  <svg
    data-testid={PILLARS_ICON}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9C8.5 7.5 8 3 8 3C8 3 7.5 7.5 6 9C4.5 10.5 2 11 2 11C2 11 4.5 11.5 6 13C7.5 14.5 8 19 8 19C8 19 8.5 14.5 10 13C11.5 11.5 14 11 14 11C14 11 11.5 10.5 10 9Z"
      stroke="#1C1C1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6667 4.75C18.7917 3.8125 18.5 1 18.5 1C18.5 1 18.2083 3.8125 17.3333 4.75C16.4583 5.6875 15 6 15 6C15 6 16.4583 6.3125 17.3333 7.25C18.2083 8.1875 18.5 11 18.5 11C18.5 11 18.7917 8.1875 19.6667 7.25C20.5417 6.3125 22 6 22 6C22 6 20.5417 5.6875 19.6667 4.75Z"
      stroke="#1C1C1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 17C16.25 16.25 16 14 16 14C16 14 15.75 16.25 15 17C14.25 17.75 13 18 13 18C13 18 14.25 18.25 15 19C15.75 19.75 16 22 16 22C16 22 16.25 19.75 17 19C17.75 18.25 19 18 19 18C19 18 17.75 17.75 17 17Z"
      stroke="#1C1C1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconPillars;
