import {
  Typography,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  Formik,
  FormikState,
  FormikHandlers,
  FormikErrors,
  Form,
} from "formik";
import React, { useState } from "react";
import {
  EMAIL,
  PASSWORD,
  HIDE,
  SHOW,
  SIGN_IN,
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  PASSWORD_REQUIRED,
} from "../constants/login.constants";
import {
  LOGIN_FORM_ID,
  LOGIN_FORM_EMAIL,
  LOGIN_FORM_PASSWORD,
  LOGIN_FORM_SIGN_IN_BUTTON,
  LOGIN_IN_PROGRESS_ID,
} from "../constants/test-ids/login.ids";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectIsLoggingIn } from "../store/selectors/authSelectors";
import { isEmailValid } from "../utils/stringHelpers";
import StyledBoxSpace from "./styled/StyledBoxSpace";
import StyledLoginButton from "./styled/StyledLoginButton";
import StyledTextField from "./styled/StyledTextField";

interface Props {
  submitForm: (formValues: FormValues) => Promise<void>;
}

export interface FormValues {
  email: string;
  password: string;
}

const LoginForm: React.FC<Props> = ({ submitForm }) => {
  const [showPassword, setShowPassword] = useState(false);
  const isLoggingIn = useAppSelector(selectIsLoggingIn);

  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const validateForm = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = EMAIL_REQUIRED;
    } else if (!isEmailValid(values.email)) {
      errors.email = EMAIL_INVALID;
    }

    if (!values.password) {
      errors.password = PASSWORD_REQUIRED;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={async (values) => {
        await submitForm(values);
      }}
    >
      {(props: FormikState<FormValues> & FormikHandlers) => {
        const { values, errors, touched, handleChange, handleBlur } = props;
        return (
          <Form data-testid={LOGIN_FORM_ID}>
            <StyledBoxSpace space={2} />
            <StyledTextField
              data-testid={LOGIN_FORM_EMAIL}
              label={EMAIL}
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              helperText={
                errors.email &&
                touched.email && (
                  <Typography variant="subtitle2">{errors.email}</Typography>
                )
              }
            />
            <StyledBoxSpace space={2} />
            <StyledTextField
              data-testid={LOGIN_FORM_PASSWORD}
              label={PASSWORD}
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password}
              helperText={
                touched.password &&
                errors.password && (
                  <Typography variant="subtitle2">{errors.password}</Typography>
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? HIDE : SHOW}
                    </Button>
                  </InputAdornment>
                ),
                type: showPassword ? "text" : "password",
              }}
            />
            <StyledBoxSpace space={2} />
            <StyledLoginButton
              data-testid={LOGIN_FORM_SIGN_IN_BUTTON}
              type="submit"
              variant="contained"
              disabled={isLoggingIn}
            >
              {isLoggingIn ? (
                <CircularProgress data-testid={LOGIN_IN_PROGRESS_ID} />
              ) : (
                SIGN_IN
              )}
            </StyledLoginButton>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
