import React from "react";

const IconDescending = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4004 17C13.4004 17.5523 13.8481 18 14.4004 18H15.8004C16.3527 18 16.8004 17.5523 16.8004 17V16.6C16.8004 16.0477 16.3527 15.6 15.8004 15.6H14.4004C13.8481 15.6 13.4004 16.0477 13.4004 16.6V17ZM14.4004 10.8C13.8481 10.8 13.4004 11.2477 13.4004 11.8V12.2C13.4004 12.7523 13.8481 13.2 14.4004 13.2H18.2004C18.7527 13.2 19.2004 12.7523 19.2004 12.2V11.8C19.2004 11.2477 18.7527 10.8 18.2004 10.8H14.4004ZM13.4004 7.4C13.4004 7.95229 13.8481 8.4 14.4004 8.4H20.6004C21.1527 8.4 21.6004 7.95228 21.6004 7.4V7C21.6004 6.44771 21.1527 6 20.6004 6H14.4004C13.8481 6 13.4004 6.44772 13.4004 7V7.4Z"
      fill="#1C1C1C"
    />
    <path
      d="M7.25 6.75L7.25 16.6667"
      stroke="#1C1C1C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M10.084 14.5834L7.25065 17.25L4.41732 14.5834"
      stroke="#1C1C1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconDescending;
