import * as React from "react";
import { GROOV_LOGO } from "../../constants/test-ids/groovLogo.ids";

function GroovLogo() {
  return (
    <svg
      data-testid={GROOV_LOGO}
      width="94"
      height="28"
      viewBox="0 0 94 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M34.8327 2.88912C34.8327 2.30911 34.591 1.77745 34.2044 1.39078C33.8177 1.00411 33.2619 0.762448 32.6819 0.762448C30.8694 0.762448 29.1535 1.29411 27.7277 2.21245C27.631 1.89828 27.4619 1.63245 27.2202 1.39078C26.8335 1.00411 26.3019 0.738281 25.7219 0.738281C25.1177 0.738281 24.5619 0.979947 24.1752 1.39078C23.7885 1.77745 23.5469 2.33328 23.5469 2.91328V9.12411H23.571C23.5469 9.36578 23.5469 9.60745 23.5469 9.87328V16.8333C23.5469 17.4133 23.7885 17.9691 24.1752 18.3558C24.5619 18.7424 25.1177 19.0083 25.7219 19.0083C26.326 19.0083 26.8577 18.7666 27.2202 18.3558C27.6069 17.9691 27.8485 17.4133 27.8485 16.8333V9.87328C27.8485 8.54411 28.3802 7.33578 29.2502 6.46578C30.1202 5.59578 31.3285 5.06412 32.6577 5.06412C33.2619 5.06412 33.8177 4.82245 34.2044 4.41161C34.6152 4.02495 34.8327 3.49328 34.8327 2.88912Z"
          fill="#006AFF"
        />
        <path
          d="M70.3316 13.2566C69.4616 14.1266 68.2532 14.6824 66.9241 14.6824C65.5949 14.6824 64.3866 14.1266 63.5166 13.2566C62.6466 12.3866 62.1149 11.1783 62.1149 9.84911C62.1149 8.51995 62.6466 7.31161 63.5166 6.44161C64.3866 5.57161 65.5949 5.03995 66.9241 5.03995C68.2532 5.03995 69.4616 5.57161 70.3316 6.44161C71.2016 7.31161 71.7332 8.51995 71.7332 9.84911C71.7574 11.1783 71.2016 12.3624 70.3316 13.2566ZM66.9241 0.738281C64.4107 0.738281 62.1391 1.75328 60.4716 3.39661C58.8282 5.03995 57.7891 7.33578 57.7891 9.84911C57.7891 12.3624 58.8282 14.6341 60.4716 16.3016C62.1149 17.9449 64.4107 18.9841 66.9241 18.9841C69.4374 18.9841 71.7332 17.9449 73.3766 16.3016C75.0199 14.6583 76.0349 12.3624 76.0349 9.84911C76.0349 7.33578 75.0199 5.03995 73.3766 3.39661C71.7332 1.75328 69.4374 0.738281 66.9241 0.738281Z"
          fill="#006AFF"
        />
        <path
          d="M48.6519 13.2566C47.7819 14.1266 46.5735 14.6824 45.2444 14.6824C43.9152 14.6824 42.7069 14.1266 41.8369 13.2566C40.9669 12.3866 40.4352 11.1783 40.4352 9.84911C40.4352 8.51995 40.9669 7.31161 41.8369 6.44161C42.7069 5.57161 43.9152 5.03995 45.2444 5.03995C46.5735 5.03995 47.7819 5.57161 48.6519 6.44161C49.5219 7.31161 50.0535 8.51995 50.0535 9.84911C50.0777 11.1783 49.5219 12.3624 48.6519 13.2566ZM45.2444 0.738281C42.731 0.738281 40.4594 1.75328 38.7919 3.39661C37.1485 5.03995 36.1094 7.33578 36.1094 9.84911C36.1094 12.3624 37.1485 14.6341 38.7919 16.3016C40.4352 17.9449 42.731 18.9841 45.2444 18.9841C47.7577 18.9841 50.0535 17.9449 51.6969 16.3016C53.3402 14.6583 54.3552 12.3624 54.3552 9.84911C54.3552 7.33578 53.3402 5.03995 51.6969 3.39661C50.0535 1.75328 47.7577 0.738281 45.2444 0.738281Z"
          fill="#006AFF"
        />
        <path
          d="M18.1553 20.4584C17.9378 20.12 17.6478 19.8542 17.2611 19.6609C16.9711 19.5159 16.6328 19.4434 16.3186 19.4434C15.9319 19.4434 15.5453 19.54 15.2069 19.7575C14.8686 19.9509 14.5786 20.265 14.4094 20.6275C13.9986 21.4734 13.3703 22.15 12.6453 22.6092C11.8961 23.0684 11.0261 23.3342 10.1078 23.3584C9.16526 23.3584 8.29526 23.0925 7.57026 22.6092C6.82109 22.15 6.21693 21.4492 5.80609 20.6275C5.61276 20.265 5.32276 19.9509 5.00859 19.7575C4.67026 19.5642 4.28359 19.4434 3.89693 19.4434C3.58276 19.4434 3.26859 19.5159 2.95443 19.6609C2.56776 19.8542 2.27776 20.12 2.06026 20.4584C1.84276 20.7967 1.74609 21.1834 1.74609 21.57C1.74609 21.8842 1.81859 22.2225 1.96359 22.5125C3.09943 24.8084 5.08109 26.4759 7.42526 27.225V27.2492L7.73943 27.3459C8.48859 27.5392 9.2861 27.66 10.0836 27.66H10.1078H10.1319C10.9536 27.66 11.7269 27.5392 12.4761 27.3459L12.7903 27.2492V27.225C15.1344 26.5 17.1161 24.8325 18.2519 22.5125C18.3969 22.1984 18.4694 21.8842 18.4694 21.57C18.4694 21.1834 18.3486 20.7967 18.1553 20.4584Z"
          fill="#74E4C6"
        />
        <path
          d="M6.65255 6.44229C7.52255 5.57229 8.73089 5.04063 10.0601 5.04063C11.3892 5.04063 12.5976 5.57229 13.4676 6.44229C14.3376 7.31229 14.8692 8.47229 14.8692 9.77729V9.92229C14.8451 11.2031 14.3134 12.3873 13.4676 13.2573C12.5976 14.1273 11.3892 14.6831 10.0601 14.6831C8.73089 14.6831 7.52255 14.1273 6.65255 13.2573C5.78255 12.3873 5.25089 11.179 5.25089 9.84979C5.25089 8.52063 5.78255 7.31229 6.65255 6.44229ZM19.1951 2.45479C19.1951 1.87479 18.9534 1.31896 18.5667 0.95646C18.1801 0.569793 17.6484 0.328125 17.0684 0.328125C16.4642 0.328125 15.9326 0.569793 15.5459 0.95646C15.2317 1.27063 15.0142 1.68146 14.9417 2.14062C13.5401 1.27062 11.8726 0.738959 10.0842 0.738959C7.57089 0.738959 5.29922 1.75396 3.63172 3.39729C1.98839 5.04063 0.949219 7.33646 0.949219 9.84979C0.949219 12.3631 1.98839 14.6348 3.63172 16.3023C5.27505 17.9456 7.57089 18.9848 10.0842 18.9848C12.5976 18.9848 14.8934 17.9456 16.5367 16.3023C18.0592 14.7798 19.0501 12.7015 19.1709 10.4298C19.1951 10.3331 19.1951 10.2365 19.1951 10.1398V2.45479Z"
          fill="#006AFF"
        />
        <path
          d="M93.0251 3.10786C93.0251 2.67286 92.8801 2.23786 92.6384 1.89953C92.3968 1.53703 92.0343 1.2712 91.6234 1.1262C91.3818 1.02953 91.1159 0.981198 90.8501 0.981198C89.9801 0.981198 89.1584 1.53703 88.8443 2.38286L85.6301 10.9862L82.3918 2.3587C82.0534 1.4887 81.2559 0.957031 80.3859 0.957031C80.1201 0.957031 79.8784 1.00536 79.6126 1.10203C79.1776 1.24703 78.8393 1.53703 78.5976 1.87536C78.3559 2.23786 78.2109 2.6487 78.2109 3.0837C78.2109 3.34953 78.2593 3.61536 78.3559 3.85703L83.5759 17.7529C83.7209 18.1637 84.0109 18.5262 84.3734 18.7679C84.7359 19.0095 85.1709 19.1787 85.6301 19.1787H85.6543C86.0893 19.1787 86.5243 19.0337 86.8868 18.792C87.2493 18.5504 87.5393 18.1879 87.7084 17.7529L92.9284 3.85703C92.9768 3.63953 93.0251 3.3737 93.0251 3.10786Z"
          fill="#006AFF"
        />
      </g>
    </svg>
  );
}

export default GroovLogo;
