import { Typography } from "@mui/material";
import React from "react";
import {
  NO_DATA_BODY_GENERIC,
  NO_DATA_TITLE_GENERIC,
} from "../../constants/noData.constants";
import {
  NO_DATA_CARD,
  NO_DATA_SUBTITLE_ID,
  NO_DATA_TITLE_ID,
} from "../../constants/test-ids/noDataCard.ids";
import StyledPaperNotes from "../styled/StyledPaperNotes";

const NoDataCard = () => {
  return (
    <StyledPaperNotes elevation={0} data-testid={NO_DATA_CARD}>
      <Typography variant="h1" data-testid={NO_DATA_TITLE_ID}>
        {NO_DATA_TITLE_GENERIC}
      </Typography>
      <Typography variant="body1" data-testid={NO_DATA_SUBTITLE_ID}>
        {NO_DATA_BODY_GENERIC}
      </Typography>
    </StyledPaperNotes>
  );
};

export default NoDataCard;
