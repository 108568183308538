export enum AnalyticsEvent {
  // UI interaction events
  OnPressedEvent = "OnPressedEvent",
  OptionSelectEvent = "OptionSelectEvent",
  LinkPressedEvent = "LinkPressedEvent",
  TabChangeEvent = "TabChangeEvent",
  OnScrollEvent = "OnScrollEvent",

  // Page view events
  ToolTipViewEvent = "ToolTipViewEvent",
  ModalViewEvent = "ModalViewEvent",
  PageViewEvent = "PageViewEvent",
  FeatureViewEvent = "FeatureViewEvent",
  OnViewEvent = "OnViewEvent",

  // Media events
  VideoPlayed = "VideoPlayedEvent",
  VideoPaused = "VideoPausedEvent",
  VideoScrubbed = "VideoScrubbedEvent",
  VideoEnded = "VideoEndedEvent",
  AudioPlayed = "VideoPlayedEvent",
  AudioPaused = "VideoPausedEvent",
  AudioScrubbed = "VideoScrubbedEvent",
  AudioEnded = "VideoEndedEvent",

  // User "does something" e.g. Login, Logout, Start / Complete a survey (hypothetically)
  UserActionEvent = "UserActionEvent",

  // Api events
  ApiCall = "ApiCallEvent",
  ApiCallSuccess = "ApiCallSuccessEvent",
  ApiCallFail = "ApiCallFailEvent",
}
