import { Grid, Typography } from "@mui/material";
import React from "react";
import BannerImage from "../assets/svgComponents/BannerImage";
import {
  BANNER_BODY_2,
  BANNER_BODY_1,
  BANNER_THANK_YOU,
  BANNER_TITLE,
  BANNER_BODY_3,
  BANNER_BODY_1_PREFIX,
} from "../constants/components/banner.constants";
import {
  BANNER_BODY_1_ID,
  BANNER_BODY_2_ID,
  BANNER_BODY_3_ID,
  BANNER_ID,
  BANNER_THANK_YOU_ID,
  BANNER_TITLE_ID,
} from "../constants/test-ids/banner.ids";
import { useAppMediaQuery } from "../hooks/useAppMediaQuery";
import groovColors from "../styles/colors";
import theme from "../styles/theme";
import StyledPaperBanner from "./styled/StyledPaperBanner";
import StyledPaperCustom from "./styled/StyledPaperCustom";
import "../styles/font.css";

const Banner = () => {
  const { mdScreenAndBelow, columns, xsScreen } = useAppMediaQuery();
  return (
    <StyledPaperBanner elevation={0} data-testid={BANNER_ID}>
      <Grid
        container
        textAlign={mdScreenAndBelow ? "center" : "inherit"}
        justifyContent={"center"}
        columns={columns}
        spacing={1}
      >
        <Grid item xs={4} sm={8} md={8} lg={10}>
          <StyledPaperCustom
            elevation={0}
            color={groovColors.mint[90]}
            margin={mdScreenAndBelow ? "auto" : ""}
            padding={`${theme.spacing(1)} ${theme.spacing(2)}`}
            borderRadius={theme.spacing(4)}
            width="fit-content"
          >
            <Typography
              variant="subtitle1"
              color={groovColors.blue[110]}
              data-testid={BANNER_THANK_YOU_ID}
            >
              {BANNER_THANK_YOU}
            </Typography>
          </StyledPaperCustom>
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={6}>
          <Grid
            container
            spacing={1}
            padding={xsScreen ? `0 ${theme.spacing(2)}` : `0 0`}
          >
            <Grid item xs={12}>
              <Typography
                variant="h1"
                color={groovColors.blue[100]}
                data-testid={BANNER_TITLE_ID}
                fontFamily={"Bugaki"}
                fontWeight={400}
                fontSize={40}
                lineHeight={"52px"}
              >
                {BANNER_TITLE}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color={groovColors.blue[130]}
                data-testid={BANNER_BODY_1_ID}
              >
                <strong>{BANNER_BODY_1_PREFIX} </strong>
                {BANNER_BODY_1}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color={groovColors.blue[130]}
                data-testid={BANNER_BODY_2_ID}
              >
                {BANNER_BODY_2}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color={groovColors.blue[130]}
                data-testid={BANNER_BODY_3_ID}
              >
                {BANNER_BODY_3}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={8}
          lg={4}
          display={"flex"}
          justifyContent={"center"}
        >
          <BannerImage />
        </Grid>
      </Grid>
    </StyledPaperBanner>
  );
};

export default Banner;
