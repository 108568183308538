import { Grid, Typography } from "@mui/material";
import React from "react";
import { ANSWER_LABEL } from "../../constants/components/answers.constants";
import {
  ANSWER_HEADER_ANSWERS_ID,
  ANSWER_HEADER_ID,
} from "../../constants/test-ids/answerHeader.ids";
import groovColors from "../../styles/colors";
import theme from "../../styles/theme";
import StyledPaperCustom from "../styled/StyledPaperCustom";

const AnswerHeader = () => {
  return (
    <StyledPaperCustom
      elevation={0}
      padding={theme.spacing(2.5)}
      borderRadius={theme.spacing(1.5)}
      color={groovColors.neutral[10]}
      width={"100%"}
      data-testid={ANSWER_HEADER_ID}
    >
      <Grid item xs={12} data-testid={ANSWER_HEADER_ANSWERS_ID}>
        <Typography variant="subtitle1" color={groovColors.neutral[60]}>
          {ANSWER_LABEL}
        </Typography>
      </Grid>
    </StyledPaperCustom>
  );
};

export default AnswerHeader;
