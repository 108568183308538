import { Grid, Typography } from "@mui/material";
import React from "react";
import PillarCelebrate from "../../assets/svgComponents/Pillars/Celebrate";
import PillarChill from "../../assets/svgComponents/Pillars/Chill";
import PillarConnect from "../../assets/svgComponents/Pillars/Connect";
import PillarDo from "../../assets/svgComponents/Pillars/Do";
import PillarEnjoy from "../../assets/svgComponents/Pillars/Enjoy";
import PillarMove from "../../assets/svgComponents/Pillars/Move";
import {
  PILLAR_BADGE_ID,
  PILLAR_BADGE_TEXT_ID,
} from "../../constants/test-ids/pillarBadge.ids";
import groovColors from "../../styles/colors";
import { Pillar } from "../../types/enums/Pillar";
import theme from "../../styles/theme";

interface Props {
  pillar: string;
}

const PillarBadge: React.FC<Props> = ({ pillar }) => {
  let image;

  switch (pillar) {
    case Pillar[Pillar.Chill]:
      image = <PillarChill />;
      break;
    case Pillar[Pillar.Do]:
      image = <PillarDo />;
      break;
    case Pillar[Pillar.Connect]:
      image = <PillarConnect />;
      break;
    case Pillar[Pillar.Celebrate]:
      image = <PillarCelebrate />;
      break;
    case Pillar[Pillar.Move]:
      image = <PillarMove />;
      break;
    case Pillar[Pillar.Enjoy]:
      image = <PillarEnjoy />;
      break;
  }

  if (!image) return <></>;

  return (
    <Grid item xs={12} sm={6} data-testid={PILLAR_BADGE_ID}>
      <Grid container>
        <Grid item xs={"auto"}>
          {image}
        </Grid>
        <Grid
          item
          xs
          display={"flex"}
          flexWrap={"wrap"}
          alignContent={"center"}
        >
          <Typography
            variant="button"
            color={groovColors.neutral[95]}
            data-testid={PILLAR_BADGE_TEXT_ID}
            style={{
              paddingLeft: theme.spacing(2),
            }}
          >
            {pillar}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PillarBadge;
